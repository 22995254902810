import React, { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPencilAlt,
  faTrash,
  faUserPlus,
  faRedo,
  faAngleDown,
} from '@fortawesome/free-solid-svg-icons';

import Modal from '../../../../elements/Modal';

import { UserList } from '~/components/dashboard';
import { Link } from 'react-router-dom';

import '../../base.css';

import { getUser } from '~/models/users';

const ContentEmpresaUsuarios = () => {
  const [users, setUsers] = useState([]);
  const getData = async () => {
    setUsers(await getUser('company'));
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <section id="dashboard-content">
      <div>
        <div className="row">
          <h1 className="flex-align-center">Usuários da Sua Empresa</h1>
        </div>

        <UserList onRefresh={getData} users={users} />
        <p id="bottom-page-action" className="text-right blue-text">
          <Link to="/usuarios/novo">
            Adicionar Usuário
            <FontAwesomeIcon icon={faUserPlus} />
          </Link>
        </p>
      </div>
    </section>
  );
};

export default ContentEmpresaUsuarios;
