import React, { useRef, useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTrash, faRedo, faCamera, faTimes } from '@fortawesome/free-solid-svg-icons';

import { ImagePicker } from 'react-file-picker';
import { useDispatch } from 'react-redux';
import { updateProfile } from '~/store/modules/user/actions';
import InputText from '~/components/form/InputText';
import BackButton from '~/components/elements/BackButton';
import history from '~/services/history';
import { Form } from '@unform/web';
import '~/components/dashboard/Content/base.css';
import './style.css';

import masks from '~/services/masks';
import Yup from '~/config/yup';
import { validateCpf } from '~/utils/validate';
import { getById, remove, save } from '~/models/users';
const FormUser = ({ admin = false, isOwn = false, showPhoto = false, title, id, isAdd = false, hideBack = false }) => {
  const [photo, setPhoto] = useState('');

  const [imageError, setImageError] = useState('');

  const handleImageChange = data => {
    setIsChanged(true);
    setPhoto(data);
  };
  const handleImageError = () => {
    setImageError('Arquivo inválido. Envie uma imagem .jpg ou .png de tamanho entre 100x100 e 500x500.');
  };
  const handleImageRemove = () => {
    setIsChanged(true);
    setPhoto(false);
    setImageError('');
  };

  const [isChanged, setIsChanged] = useState(isAdd);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [birthday, setBirthday] = useState('');
  const [cpf, setCpf] = useState('');
  const [disabled, setDisabled] = useState(false);
  const getData = async () => {
    // console.log('GET DATA');
    if (!isAdd) {
      const obj = await getById(id);
      if (obj.name) {
        setName(obj.name);
      }
      if (obj.email) {
        setEmail(obj.email);
      }
      if (obj.birthday) {
        setBirthday(obj.birthday);
      }
      if (obj.cpf) {
        setCpf(obj.cpf);
      }
      if (obj.photo) {
        setPhoto(obj.photo);
      }
    }
  };
  useEffect(() => {
    getData();
  }, [id]);

  const handleDelete = async e => {
    e.preventDefault();
    await remove(id);
    history.back();
  };

  const dispatch = useDispatch();

  const handleSubmit = async data => {
    if (!disabled) {
      try {
        setDisabled(true);
        // Remove all previous errors
        formRef.current.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required(),
          email: Yup.string()
            .email()
            .required(),
          cpf: Yup.string()
            .required()
            .test('is-cpf', 'Esse não é um cpf válido', async value => {
              return await validateCpf(masks.cpf.remove(value));
            }),
          birthday: Yup.string().required(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
        // Validation passed
        if (isOwn) {
          dispatch(updateProfile({ ...data, id, photo }));
        } else {
          if (await save(id, { ...data, photo, admin })) {
            if (!id) {
              history.back();
            }
            setIsChanged(false);
          }
        }
        setDisabled(false);
      } catch (err) {
        setDisabled(false);
        const validationErrors = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message;
          });
          formRef.current.setErrors(validationErrors);
        }
      }
    }
    // await user.remove(user_id);
  };

  const handleBack = e => {
    e.preventDefault();
    // history.back();
    getData();
  };
  const handleChange = (key, value) => {
    setIsChanged(true);

    switch (key) {
      case 'name':
        return setName(value);
      case 'email':
        return setEmail(value);
      case 'birthday':
        return setBirthday(value);
      case 'cpf':
        return setCpf(value);
      default:
        return;
    }
  };

  const formRef = useRef(null);
  return (
    <section id="dashboard-content">
      <Form ref={formRef} onSubmit={handleSubmit}>
        <div>
          <div className="row">
            <h1 className="flex-align-center">
              {!hideBack && <BackButton />}
              {title}
            </h1>
          </div>

          <div className="row">
            <InputText
              name="name"
              label="Nome"
              placeholder="Digite o nome"
              value={name}
              mandatory={true}
              handleChange={e => handleChange('name', e)}
            />
            <InputText
              name="email"
              label="E-mail"
              placeholder="Digite o e-mail"
              value={email}
              mandatory={true}
              handleChange={e => handleChange('email', e)}
            />
          </div>
          <div className="row">
            <InputText
              name="birthday"
              label="Data de nascimento"
              placeholder="00/00/0000"
              mask="date"
              value={birthday}
              mandatory={true}
              handleChange={e => handleChange('birthday', e)}
            />
            <InputText
              name="cpf"
              label="CPF"
              placeholder="Digite o CPF do usuário"
              mask="cpf"
              value={cpf}
              mandatory={true}
              handleChange={e => handleChange('cpf', e)}
            />
          </div>
          {showPhoto && (
            <div id="photo-wrapper" className="is-flex flex-align-center">
              {photo ? (
                <>
                  <img alt="user" className="user-photo" src={photo} />
                  <div>
                    <ImagePicker
                      extensions={['jpg', 'jpeg', 'png']}
                      dims={{
                        minWidth: 100,
                        maxWidth: 500,
                        minHeight: 100,
                        maxHeight: 500,
                      }}
                      onChange={handleImageChange}
                      onError={handleImageError}
                    >
                      <p className="blue-text">
                        <FontAwesomeIcon icon={faCamera} /> Alterar
                      </p>
                    </ImagePicker>
                    <p className="lightgray-text" onClick={() => handleImageRemove()}>
                      <FontAwesomeIcon icon={faTimes} /> Remover
                    </p>

                    <p>{imageError}</p>
                  </div>
                </>
              ) : (
                <>
                  <img alt="user" className="user-photo" src="/images/foto-usuario-vazio.png" />
                  <div>
                    <ImagePicker
                      extensions={['jpg', 'jpeg', 'png']}
                      dims={{
                        minWidth: 100,
                        maxWidth: 500,
                        minHeight: 100,
                        maxHeight: 500,
                      }}
                      onChange={handleImageChange}
                      onError={handleImageError}
                    >
                      <p className="blue-text">
                        <FontAwesomeIcon icon={faCamera} /> Adicionar
                      </p>
                    </ImagePicker>

                    <p>{imageError}</p>
                  </div>
                </>
              )}
            </div>
          )}
          {!showPhoto && !isAdd && (
            <p onClick={handleDelete} id="bottom-page-action" className="orange-text">
              Excluir usuário
              <FontAwesomeIcon icon={faTrash} />
            </p>
          )}
        </div>

        {isChanged && (
          <div className="is-flex direction-row-reverse">
            <button type="submit" id="button">
              Salvar e finalizar
              <FontAwesomeIcon icon={faCheck} />
            </button>{' '}
            <button onClick={e => handleBack(e)} id="gray-button">
              Descartar alterações
              <FontAwesomeIcon icon={faRedo} flip="horizontal" />
            </button>
          </div>
        )}
      </Form>
    </section>
  );
};

export default FormUser;
