import React, { useRef, useState } from 'react';

import InputText from '../../form/InputText';

import { useHistory } from 'react-router-dom';
import './styles.css';
import { Form } from '@unform/web';
import Yup from '~/config/yup';

import { forgot } from '~/models/auth';
const RecuperarSenha = () => {
  let history = useHistory();
  const [email, setEmail] = useState('');
  const [isInvalidMail, setIsInvalidMail] = useState(false);

  const handleChange = async value => {
    setIsInvalidMail(false);
    setEmail(value);
  };
  const handleSubmit = async (data, e) => {
    // e.preventDefault();
    // console.log('handleSubmit', e);
    try {
      // Remove all previous errors
      formRef.current.setErrors({});
      const schema = Yup.object().shape({
        email: Yup.string()
          .email()
          .required(),
      });
      await schema.validate(data, {
        abortEarly: false,
      });
      // Validation passed
      const response = await forgot(data.email);
      if (response) {
        history.push({
          pathname: '/verifique-seu-email',
          // search: `?email=${data.email}`,
          state: { email: data.email },
        });
        // .go({
        //   pathname: '/verifique-seu-email',
        //   // search: `?email=${data.email}`,
        //   state: { email: data.email },
        // });
      } else {
        const validationErrors = {};
        validationErrors['email'] = 'O e-mail informado não está cadastrado.';
        formRef.current.setErrors(validationErrors);
      }
    } catch (err) {
      // console.log('catch', err);
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          // console.log('path', error.path);
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  };

  const formRef = useRef(null);
  return (
    <section id="recuperar-senha">
      <div className="page-wrapper center">
        <h1>Recupere sua senha</h1>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <div className="row">
            <InputText
              name="email"
              label="Digite o e-mail cadastrado"
              invalid={isInvalidMail}
              errorMessage="O e-mail informado não está registrado na InPACTO"
              mandatory={true}
              placeholder="Digite seu e-mail"
              value={email}
              handleChange={e => handleChange(e)}
            />
          </div>
          <button className="button btn-primary">Recuperar Senha</button>
        </Form>
      </div>
    </section>
  );
};

export default RecuperarSenha;
