import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChartBar,
  faCog,
  faUserAlt,
} from '@fortawesome/free-solid-svg-icons';

import { Link } from 'react-router-dom';

import './styles.css';

const Sidebar = props => {
  const user = useSelector(state => state.user.profile);
  const activeClass = menu => {
    if (menu === props.active) {
      return 'link-button active';
    }
    return 'link-button';
  };

  const [hovered, setHovered] = useState(false);

  return (
    <section
      id="dashboard-sidebar"
      className={hovered ? 'open' : ''}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <button className={activeClass('dashboards')}>
        <Link to="/dashboard">
          <FontAwesomeIcon className="sidebar-icon" icon={faChartBar} />
          <span>Dashboards</span>
        </Link>
      </button>
      {user && (user.type_id == 1 || user.principal == 1) && (
        <button className={activeClass('configuracoes')}>
          <Link
            to={`${
              user.type_id == 1 ? '/empresas-associadas' : '/sua-empresa'
            }`}
          >
            <FontAwesomeIcon className="sidebar-icon" icon={faCog} />
            <span>Configurações</span>
          </Link>
        </button>
      )}
      <button className={activeClass('minha-conta')}>
        <Link to="/minha-conta">
          <FontAwesomeIcon className="sidebar-icon" icon={faUserAlt} />
          <span>Minha Conta</span>
        </Link>
      </button>
    </section>
  );
};

export default Sidebar;
