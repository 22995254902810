import React from 'react';

import {
  // AdministradoresShow,
  Footer,
  Header,
  Nav,
  Sidebar,
} from '../../../components/dashboard';

import '../../../style.css';

const ShowPage = () => {
  document.title = 'InPACTO | Exibir Administrador';

  return (
    <>
      <Header />

      <section id="container">
        <Sidebar active="configuracoes" />

        <div id="content-wrapper">
          <Nav type="configuracoes" active="administradores" />
          {/* <AdministradoresShow /> */}
          <Footer />
        </div>
      </section>
    </>
  );
};

export default ShowPage;
