import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { signOut } from '~/store/modules/auth/actions';
import './styles.css';
import { Link } from 'react-router-dom';

const Header = props => {
  const logoEmpresa = () => {
    // if (props.logo) {
    //   return <img id="logo-empresa" alt="logo empresa" src={props.logo} />;
    // }
    return '';
  };

  const user = useSelector(state => state.user.profile);
  const dispatch = useDispatch();
  const handleSignOut = () => {
    dispatch(signOut());
  };
  return (
    <section id="dashboard-header">
      <span id="logo-wrapper">
        <img id="logo" alt="logo" src="/images/logo-inpacto.png" />
        {logoEmpresa()}
      </span>
      {user && (
        <p id="userdata">
          Olá, {user.name}
          <Link to={`minha-conta`}>
            {!user.photo ? (
              <img id="user-photo" alt="user" src={`https://ui-avatars.com/api/?size=40&background=random&name=${user.name}`} />
            ) : (
              <img id="user-photo" alt="user" src={user.photo} />
            )}
          </Link>
          <button onClick={handleSignOut}>
            <FontAwesomeIcon id="logout-button" icon={faSignOutAlt} />
          </button>
        </p>
      )}
    </section>
  );
};

export default Header;
