import api from '~/services/api';

import { toast } from 'react-toastify';
const send = async (data, notify = true) => {
  try {
    const response = await api.post('contact', data);
    if (response.status !== 200) {
      toast.error('Erro ao enviar contato');
      return false;
    } else {
      if (notify) {
        toast.success('Contato enviado com sucesso');
      }
      return true;
    }
  } catch (error) {
    // console.log('error', error);
    toast.error('Erro ao enviar contato');
    return false;
    //toasty
  }
};
export { send };
