import React from 'react';
import Title from '../../elements/Title';
import Question from '../../elements/Question';

import './styles.css';

const Faq = () => {
  return (
    <section id="faq" name="faq">
      <Title content="Perguntas Frequentes" color="blue" />

      <Question question="O que é o InPACTO?">
        <p>
          InPACTO (Instituto Pacto pela Erradicação do Trabalho Escravo) é uma
          organização sem fins lucrativos que tem como missão promover a
          prevenção e a erradicação do trabalho escravo nas cadeias produtivas
          de empresas nacionais e internacionais que atuam no Brasil, por meio
          da criação de ferramentas, metodologias e construção de confiança e
          credibilidade entre aqueles que participam das ações desenvolvidas.
        </p>
        <p>
          O InPACTO também faz a gestão do Pacto Nacional pela Erradicação do
          Trabalho Escravo, estimula o protagonismo do setor produtivo no
          combate ao trabalho escravo e promove o diálogo intersetorial e
          multistakeholder para que empresas, entidades representativas,
          organizações da sociedade civil, nacionais e internacionais e governos
          construam soluções coletivas para a prevenção e erradicação do
          trabalho escravo nas cadeias produtivas brasileiras.
        </p>
        <p>
          Para saber mais, acesse:{' '}
          <a target="_blank" href="https://inpacto.org.br/">
            https://inpacto.org.br/
          </a>
        </p>
      </Question>
      <Question question="O que é o Índice de Vulnerabilidade InPACTO?">
        <p>
          O Índice de Vulnerabilidade InPACTO (IVI) é uma tecnologia inovadora,
          concebida pelo instituto e inédita no Brasil e no mundo. Foi
          desenvolvido e será constantemente atualizado a partir da análise e do
          cruzamento de centenas de dados socioeconômicos e demográficos que
          revelam o nível de vulnerabilidade das populações. Assim, permitem
          estabelecer uma escala de risco para incidência naquele município de
          trabalho escravo ou a qualquer outra violação de direitos humanos,
          como trabalho infantil.
        </p>
        <p>
          Com o IVI, é possível que empresas e outros interessados, associados
          ao InPACTO, possam observar quais vulnerabilidades precisam ser
          endereçadas de forma emergencial e, a partir disso, elaborar e
          executar — com o apoio técnico do InPACTO — planos de ação coletivos e
          locais para remediar os riscos de violação de direitos humanos e
          promover a dignidade e trabalho decente.
        </p>
        <p>Se interessou? Marque uma conversa com a gente!</p>
        <p>
          Escreva para Mércia Silva (
          <a target="_blank" href="mailto:mercia@inpacto.org.br">
            mercia@inpacto.org.br
          </a>
          ), Diretora Executiva do InPACTO, ou Daniele Martins (
          <a target="_blank" href="mailto:dmartins@inpacto.org.br">
            dmartins@inpacto.org.br
          </a>
          ), Coordenadora de Projetos.
        </p>
      </Question>
      <Question question="Para que serve o Índice de Vulnerabilidade InPACTO?">
        <p>
          O Índice de Vulnerabilidade InPACTO (IVI) foi idealizado para que
          empresas e setores compreendam de forma comparativa sua cadeia
          produtiva e os riscos para a incidência dessa violação. E, diante
          dessa análise técnica e objetiva, estabeleçam prioridades e planos de
          ações de prevenção de violações de direitos humanos e promoção do
          trabalho decente em suas cadeias produtivas, mais especificamente no
          combate ao trabalho escravo e infantil no Brasil.
        </p>
        <p>
          O IVI colabora:
          <ol>
            <li>
              Para a conformidade com os Princípios Orientadores sobre Empresas
              e Direitos Humanos da ONU, que estabelecem regras internacionais
              para que empresas previnam graves violações de direitos humanos e
              protejam pessoas em situação de vulnerabilidade nas suas cadeias
              produtivas, com base em três pilares: Proteger, Respeitar e
              Remediar.{' '}
            </li>
            <li>
              Para o cumprimento dos Objetivos do Desenvolvimento Sustentável
              (ODS) que remetem à construção de uma sociedade mais justa e que
              respeita os direitos humanos, e, mais especificamente, a meta 8.7:
              &quot;Tomar medidas imediatas e eficazes para erradicar o trabalho
              forçado, acabar com a escravidão moderna e o tráfico de pessoas e
              assegurar a proibição e eliminação das piores formas de trabalho
              infantil, incluindo recrutamento e utilização de crianças-soldado,
              e, até 2025, acabar com o trabalho infantil em todas as suas
              formas&quot;.
            </li>
            <li>
              Para qualificar planos de ações com base em dados e,
              consequentemente, permitir que empresas elaborem relatórios de
              compliance exigidos por algumas legislações internacionais como o
              UK ACT (2015) de modo transparente e efetivo.
            </li>
          </ol>
        </p>
      </Question>
      <Question question="Como acessar o Índice de Vulnerabilidade InPACTO?">
        <p>
          O público em geral tem acesso ao Índice de Vulnerabilidade a nível
          estadual para ter a oportunidade de conhecer um pouco da
          potencialidade da ferramenta. Entretanto, apenas os associados do
          InPACTO têm acesso ao Índice de Vulnerabilidade a nível municipal, com
          informações adicionais relevantes sobre as variáveis que ajudam a
          compor o Índice. Esse acesso se dá através de login e senha fornecidos
          pela nossa equipe.
        </p>
        <p>
          O objetivo do Índice de Vulnerabilidade InPACTO é permitir que os
          associados tenham informações de qualidade para elaborarem planos de
          ação de prevenção e combate ao trabalho escravo no Brasil. Entretanto,
          mais do que apenas fornecer o resultado das análises, o InPACTO se
          propõe a participar da busca por soluções a partir da sua experiência
          com o tema.
        </p>
      </Question>
      <Question question="Como ser associado do InPACTO?">
        <p>
          Estar no InPACTO é mostrar-se em igualdade em termos de protagonismo
          na defesa de direitos humanos e da dignidade humana, não importando
          seu tamanho ou sua posição no elo da cadeia produtiva. Para se
          associar, clique em Associe-se ao InPACTO ou acesse{' '}
          <a target="_blank" href="https://inpacto.org.br/faca-parte/">
            https://inpacto.org.br/faca-parte/
          </a>{' '}
          e obtenha mais informações sobre a associação. Ou, ainda, mande um
          e-mail para Milene Veiga Almeida (
          <a target="_blank" href="mailto:m.almeida@inpacto.org.br">
            m.almeida@inpacto.org.br
          </a>
          ), Coordenadora de Mobilização e Relacionamento do InPACTO,
          solicitando mais informações.
        </p>
      </Question>
      <Question question="Onde saber mais sobre o tema e o instituto?">
        <p>
          No site{' '}
          <a target="_blank" href="https://inpacto.org.br/">
            https://inpacto.org.br/
          </a>
          , você tem acesso a mais informações sobre o instituto, sua área de
          atuação e projetos, além de notícias relacionadas ao trabalho escravo
          e direitos humanos no Brasil e no mundo.
        </p>
      </Question>
    </section>
  );
};

export default Faq;
