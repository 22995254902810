import React from 'react';

import { HashLink as Link } from 'react-router-hash-link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import './styles.css';

const Banner = () => {
  return (
    <section id="banner">
      <p id="banner-title">
        Inteligência de dados para o<span id="banner-highlight">combate ao trabalho</span>
        <span id="banner-highlight">escravo e infantil</span>
      </p>

      <p id="banner-subtitle">
        Tecnologia desenvolvida para aprimorar as ações de prevenção a formas inaceitáveis de trabalho no Brasil
      </p>

      <a href="https://www.youtube.com/watch?v=M2AFvS9MfHI" target="_blank" className="btn-primary" id="button">
        Assista ao vídeo
      </a>

      <Link smooth to="/#mapa" id="arrow" className="is-visible-desktop">
        <FontAwesomeIcon icon={faArrowDown} />
      </Link>
    </section>
  );
};

export default Banner;
