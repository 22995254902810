import React, { useState } from 'react';

import { faPencilAlt, faTrash, faAngleDown, faRedo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Modal from '~/components/elements/Modal';
import masks from '~/services/masks';

import * as user from '~/models/users';
function UserList({ admin = false, users = [], onRefresh }) {
  const profile = useSelector(state => state.user.profile);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalSmall] = useState(true);
  const [isLevelInvalid, setIsLevelInvalid] = useState(false);

  const [activeItem, setActiveItem] = useState([]);
  const [principalItem, setPrincipalItem] = useState([]);
  const [activeValue, setActiveValue] = useState('Normal');

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const nivelAcesso = item => {
    // console.log('Nivel Acesso', item);
    setActiveItem(item);
    setActiveValue(item.principal == 1 ? 'Principal' : 'Normal');

    toggleModal();
  };

  // TODO: !!
  const confirmChange = async value => {
    // console.log('confirmChange', value);
    if (value === false) {
      setActiveItem([]);
      setIsLevelInvalid(false);
    } else {
      if (await user.setPrincipal(activeItem.id)) {
        // console.log('Salva', principalItem, activeItem);

        setActiveItem([]);
        setIsLevelInvalid(false);

        onRefresh && onRefresh();
      }
    }

    toggleModal();
  };

  //TODO: !!!
  const handleActiveValue = value => {
    // console.log('handleActiveValue', value, users);
    if (value === 'Principal') {
      let exists = users.find(item => {
        return item.principal != '1';
      });
      if (exists) {
        setIsLevelInvalid(true);
        setPrincipalItem(exists);
        return false;
      }
    }
    setActiveValue(value);
    const elementIndex = users.findIndex(element => element.cpf === activeItem.cpf);
    let nUsers = [...users];

    nUsers[elementIndex] = {
      ...nUsers[elementIndex],
      nivel: value,
    };

    onRefresh && onRefresh();
  };
  const handleDeleteUser = async (e, user_id) => {
    e.preventDefault();
    await user.remove(user_id);
    onRefresh && onRefresh();
  };
  return (
    <>
      <div>
        <table>
          <thead>
            <tr>
              <th>Usuário</th>
              <th>Email</th>
              <th>CPF</th>
              {!admin && <th>Nível de acesso</th>}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {users &&
              users.map((data, index) => (
                <tr key={index} className={'nivel-' + (data.principal == '1' ? 'Principal' : 'Normal')}>
                  <td className="flex-align-center">
                    {!data.photo ? (
                      <img
                        className="user-photo"
                        alt="user"
                        src={`https://ui-avatars.com/api/?size=40&background=random&name=${data.name}`}
                      />
                    ) : (
                      <img className="user-photo" alt="user" src={data.photo} />
                    )}

                    {data.name}
                  </td>
                  <td>{data.email}</td>
                  <td>{masks.cpf.regx(data.cpf)}</td>

                  {!admin &&
                    (profile.type_id == 1 && data.principal != '1' ? (
                      <td className="nivel-usuario" onClick={() => nivelAcesso(data)}>
                        {data.principal == '1' ? 'Principal' : 'Normal'}
                        <FontAwesomeIcon icon={faAngleDown} />
                      </td>
                    ) : (
                      <td>{data.principal == '1' ? 'Principal' : 'Normal'}</td>
                    ))}
                  <td className="text-right">
                    <Link to={`/editar-usuario/${data.id}`}>
                      <button className="link-button">
                        <FontAwesomeIcon icon={faPencilAlt} />
                      </button>
                    </Link>
                    {data.principal != '1' && profile.id !== data.id && (
                      <button onClick={e => handleDeleteUser(e, data.id)} className="link-button">
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <Modal
        open={isModalOpen}
        toggle={toggleModal}
        isSmall={isModalSmall}
        isExtraSmall={!isLevelInvalid}
        noButton={true}
        onClose={() => confirmChange(false)}
      >
        {isLevelInvalid === false ? (
          <div id="content-tipo-usuario">
            <p className="radio">
              <input
                value="Principal"
                name="tipo"
                type="radio"
                id="tipo-principal"
                checked={activeValue === 'Principal'}
                onChange={() => handleActiveValue('Principal')}
              />
              <label for="tipo-principal">Principal</label>
            </p>
            <p className="radio">
              <input
                value="Normal"
                name="tipo"
                type="radio"
                id="tipo-normal"
                checked={activeValue === 'Normal'}
                onChange={() => handleActiveValue('Normal')}
              />
              <label for="tipo-normal">Normal</label>
            </p>
          </div>
        ) : (
          <>
            <h2>Atenção</h2>
            <p>
              Apenas <b>um usuário</b> pode ser selecionado como <b>Principal</b>. Por isso, se você realmente deseja alterar o
              nível de acesso deste usuário ({activeItem.name}), modificará, automaticamente, o usuário anterior (
              {principalItem.name}) para nível <b>Normal</b>.
            </p>
            <div className="text-right">
              <button onClick={() => confirmChange(false)} className="button link-button">
                Desfazer e retornar
                <FontAwesomeIcon icon={faRedo} flip="horizontal" />
              </button>
              <button onClick={() => confirmChange(true)} className="button orange-button">
                Tudo certo, confirmar
              </button>
            </div>
          </>
        )}
      </Modal>
    </>
  );
}

export default UserList;
