import React from 'react';

import { Link } from 'react-router-dom';
import './styles.css';

const Erro404 = () => {
  return (
    <section id="pagina-de-erro">
      <div className="page-wrapper center">
        <span id="codigo-erro">404</span>
        <h1>Página não encontrada</h1>

        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p>
        <p>
          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
          nisi ut aliquip ex ea commodo consequat.
        </p>
        <p>Duis aute irure dolor in reprehenderit.</p>

        <Link onClick="" className="button btn-primary">
          Voltar para a tela inicial
        </Link>
      </div>
    </section>
  );
};

export default Erro404;
