/* eslint-disable no-useless-catch */
import api from '~/services/api';

const forgot = async email => {
  try {
    const response = await api.post(`auth/forgot`, { email });

    if (response) {
      const { data, status } = response;
      if (status === 200 || status === 201) {
        return true;
      }
      return false;
    }
    return false;
  } catch (err) {
    return false;
  }
};
const login = async (email, password, keep) => {
  try {
    const response = await api.post(`auth`, { email, password, keep });
    if (response) {
      const { data, status } = response;
      if (status === 200 || status === 201) {
        const { token, user } = data;
        return {
          token,
          user,
        };
      }
      return false;
    }
    return false;
    throw 'Something get wrong';
  } catch (err) {
    return false;
    throw err;
  }
};

export { login, forgot };
