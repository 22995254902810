import React, { useEffect, useRef, useState } from 'react';

import { Multiselect } from 'react-widgets';

import { useField } from '@unform/core';
import Label from '../Label';
import './styles.css';

function MultiSelect(props) {
  const inputRef = useRef(null);

  const { fieldName, defaultValue, registerField, error } = useField(props.name);
  const [selected, setSelected] = useState(defaultValue);

  //TODO: vfazer multi select chegar no data;
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: ref => {
        if (!ref.props || !ref.props.value) {
          return '';
        }
        return ref.props.value;
      },
      setValue: ref => {
        // console.log('SETTTT', ref, inputRef);
      },
      // path: 'value',
      // setValue(ref, value) {
      //   setSelected(value);

      //   console.log('mult set value', ref, value, selected, inputRef);
      //   // ref.setInputValue(v);
      // },
      // clearValue(ref) {
      //   ref.setInputValue('');
      // },
    });
  }, [fieldName, registerField]);
  useEffect(() => {
    // console.log('SELECTED', selected, inputRef.current);
  }, [selected]);
  const handleChange = event => {
    // setValue(event.target.value);
    // console.log('handleChange', event, inputRef);
    // setSelected(e)
  };

  return (
    <div className={`input-box ${error ? 'invalid-input' : ''}`}>
      <Label text={props.label} mandatory={props.mandatory} />
      {JSON.stringify(selected)}
      <Multiselect
        ref={inputRef}
        defaultValue={defaultValue}
        value={selected}
        valueField="id"
        textField="name"
        id={props.id}
        messages={{
          emptyList: 'Nenhum item nesta lista',
          emptyFilter: 'O filtro não retornou resultados',
        }}
        onChange={handleChange}
        selectIcon={''}
        {...props}
      />
      {error && (
        <p
          // className="error-message"
          dangerouslySetInnerHTML={{
            __html: error,
          }}
        ></p>
      )}
    </div>
  );
}

export default MultiSelect;
