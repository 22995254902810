import React from 'react';
import Title from '../../elements/Title';

import './styles.css';

const ApoiadoresSection = () => {
  return (
    <span id="apoiadores-wrapper" className="text-center">
      <Title content="Apoiadores do Índice de Vulnerabilidade InPACTO" color="orange" />

      <div id="apoiadores">
        <div>
          <img alt="logo da Vale" src="/images/logo-vale.jpg" />
        </div>
      </div>
    </span>
  );
};

export default ApoiadoresSection;
