import React from 'react';

import { CompanyList, Footer, Header, Nav, Sidebar } from '../../../components/dashboard';

import '../../../style.css';

const ListPage = () => {
  document.title = 'InPACTO | Empresas Associadas';

  return (
    <>
      <Header />

      <section id="container">
        <Sidebar active="configuracoes" />

        <div id="content-wrapper">
          <Nav type="configuracoes" active="associadas" />
          <CompanyList title="Empresas Associadas" type="1" />
          <Footer />
        </div>
      </section>
    </>
  );
};

export default ListPage;
