import React, { useState, useRef } from 'react';

import InputText from '../../form/InputText';
import Modal from '../../elements/Modal';

import { Form } from '@unform/web';
import './styles.css';
import Yup from '~/config/yup';
import { signInRequest } from '~/store/modules/auth/actions';
import { useDispatch } from 'react-redux';

import ModalSupporter from '~/components/sections/ModalSupporter';
const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [keep, setKeep] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = e => {
    e.preventDefault();
    setIsModalOpen(true);
  };

  const dispatch = useDispatch();
  const handleSubmit = async (data, e) => {
    // e.preventDefault();
    try {
      // Remove all previous errors
      formRef.current.setErrors({});
      const schema = Yup.object().shape({
        email: Yup.string()
          .email()
          .required(),
        password: Yup.string()
          .min(8)
          .required('Senha é obrigatoria'),
        // .matches(
        //   /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
        //   'Mínimo de 8 caracteres, uma letra maiúscula, uma letra maiúscula, uma minúscula, um número e um caractere especial. Ex: Inpacto@20'
        // ),
      });
      await schema.validate(data, {
        abortEarly: false,
      });
      // Validation passed
      dispatch(signInRequest(data.email, data.password, keep));
      return true;
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  };

  const formRef = useRef(null);
  return (
    <section id="login">
      <Form ref={formRef} onSubmit={handleSubmit} className="login-wrapper">
        <h1>Acesse sua conta</h1>
        <div className="row">
          <InputText
            label="E-mail"
            name="email"
            placeholder="Digite seu e-mail"
            value={email}
            handleChange={e => setEmail(e)}
          />
        </div>
        <div className="row">
          <InputText
            type="password"
            name="password"
            label="Senha <a href='/esqueci-minha-senha'>| Esqueci minha senha</a>"
            labelhtml={true}
            placeholder="Digite sua senha"
            value={password}
            handleChange={e => setPassword(e)}
          />
        </div>
        <p>
          <input checked={keep} onChange={e => setKeep(e.target.checked)} type="checkbox" value="0" name="keep" id="keep" />
          <label htmlFor="keep">Lembrar os dados de acesso</label>
        </p>

        <button type="submit" className="button btn-primary">
          Acessar minha conta
        </button>
        <hr />
        <p>Ainda não se associou ao InPACTO?</p>
        <button onClick={e => openModal(e)} className="button btn-secondary">
          Associe-se agora
        </button>
      </Form>

      <ModalSupporter onClose={() => setIsModalOpen(false)} open={isModalOpen} noButton={false} />
    </section>
  );
};

export default Login;
