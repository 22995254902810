import React, { useRef, useEffect } from 'react';

import Label from '../Label';

import { useField } from '@unform/core';
import './styles.css';

const Select = props => {
  // const [value, setValue] = useState();

  const handleChange = event => {
    // setValue(event.target.value);
    // console.log(event.target.value);
    props.handleChange(event.target.value);
  };

  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(props.name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: ref => {
        // if (props.isMulti) {
        //   if (!ref.state.value) {
        //     return [];
        //   }
        //   return ref.state.value.map(option => option.value);
        // }
        if (!selectRef.current || !selectRef.current.value) {
          return '';
        }
        return selectRef.current.value;
      },
    });
  }, [fieldName, registerField, props.isMulti]);

  return (
    <div class={` ${error ? 'invalid-input' : ''}`}>
      <Label text={props.label} mandatory={props.mandatory} />
      <select
        defaultValue={defaultValue}
        ref={selectRef}
        value={props.value}
        id={props.id}
        className={`dashboard-select ${error ? 'invalid-input' : ''}`}
        onChange={handleChange}
      >
        <option value="">{props.placeholder}</option>

        {props.options.map((item, index) => (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        ))}
      </select>

      {error && (
        <p
          // className="error-message"
          dangerouslySetInnerHTML={{
            __html: error,
          }}
        ></p>
      )}
    </div>
  );
};

export default Select;
