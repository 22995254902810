import React from 'react';

import { Header, Rodape, Erro404 } from '../../components/sections';
import { useLocation } from 'react-router-dom';

import '../../style.css';

const Erro404Page = () => {
  document.title = 'InPACTO | Página não encontrada';

  const location = useLocation();
  // console.log('404', location);
  return (
    <div id="login-page-wrapper">
      <Header empty={true} />
      <Erro404 />
      <Rodape />
    </div>
  );
};

export default Erro404Page;
