import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import InputMask from 'react-input-mask';

import masks from '~/services/masks';
import Label from '../Label';

import './styles.css';

const InputText = props => {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(props.name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref, value) {
        ref.setInputValue(value);
      },
      clearValue(ref) {
        ref.setInputValue('');
      },
    });
  }, [fieldName, registerField]);
  const mask = props.mask;

  let classlist = 'dashboard-input ' + props.classlist;

  let wrapperClasslist = '';

  let type = 'text';

  if (props.invalid === true) {
    wrapperClasslist = 'invalid-input';
  }

  if (props.type) {
    type = props.type;
  }
  const handleChange = event => {
    // setValue(event.target.value);
    // console.log(event.target.value);
    props.handleChange(event.target.value);
  };

  return (
    <div className={`input-box ${wrapperClasslist} ${error ? 'invalid-input' : ''}`}>
      <Label text={props.label} labelhtml={props.labelhtml} mandatory={props.mandatory} />
      {mask ? (
        <InputMask
          ref={inputRef}
          defaultValue={defaultValue}
          className={classlist}
          mask={masks[mask].mask}
          value={props.value}
          placeholder={props.placeholder}
          onChange={handleChange}
        />
      ) : (
        <input
          ref={inputRef}
          defaultValue={defaultValue}
          className={classlist}
          type={type}
          value={props.value}
          placeholder={props.placeholder}
          onChange={handleChange}
        />
      )}

      {props.invalid === true && props.errorMessage ? <p>{props.errorMessage}</p> : ''}
      {props.invalid === true && props.errorMessage2 ? (
        <p
          className="error-message"
          dangerouslySetInnerHTML={{
            __html: props.errorMessage2,
          }}
        ></p>
      ) : (
        ''
      )}

      {error && (
        <p
          // className="error-message"
          dangerouslySetInnerHTML={{
            __html: error,
          }}
        ></p>
      )}
    </div>
  );
};

export default InputText;
