import produce, { enableAllPlugins } from 'immer';
enableAllPlugins();

const INITIAL_STATE = {
  profile: null,
};

export default function user(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    // console.log(action.payload);
    switch (action.type) {
      case '@auth/SIGN_IN_SUCCESS': {
        draft.profile = {
          ...action.payload.user,
        };
        break;
      }
      case '@user/UPDATE_PROFILE_SUCCESS': {
        draft.profile = {
          ...draft.profile,
          ...action.payload.profile,
        };
        break;
      }
      case '@user/UPDATE_FIRST': {
        draft.profile = {
          ...draft.profile,
          first: false,
        };
        break;
      }

      case '@auth/SIGN_OUT': {
        draft.profile = null;
        break;
      }
      default:
    }
  });
}
