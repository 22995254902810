import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import './styles.css';

const Question = props => {
  const [opened, setOpened] = useState(false);
  return (
    <details className="question-element">
      <summary onClick={() => setOpened(!opened)}>
        <span>{props.question}</span>
        {}
        <FontAwesomeIcon className="icon" icon={!opened ? faChevronDown : faChevronUp} />
      </summary>
      {props.children}
    </details>
  );
};

export default Question;
