import React, { useState, useEffect } from 'react';

import { Form } from '@unform/web';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPencilAlt, faTrash, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { get, remove } from '~/models/companies';
import { getAll } from '~/models/segment';
import masks from '~/services/masks';
import { useParams, useLocation } from 'react-router-dom';
import '~/components/dashboard/Content/base.css';

import InputText from '~/components/form/InputText';
import Select from '~/components/form/Select';
import Footer from '~/components/dashboard/Footer';
import { Link } from 'react-router-dom';

const CompanyList = ({ type, title }) => {
  const [resultados, setResultados] = useState([]);
  const [segments, setSegments] = useState([]);
  const [segment, setSegment] = useState(null);
  const [search, setSearch] = useState(null);

  useEffect(() => {
    const getSegments = async () => {
      setSegments(await getAll());
    };
    getSegments();
  }, []);

  const getData = async () => {
    setResultados(await get({ segment, search, type }));
  };
  useEffect(() => {
    getData();
  }, [segment, search]);

  const handleDelete = async id => {
    await remove(id);
    getData();
  };
  const location = useLocation();
  // console.log('location', location);
  return (
    <section id="dashboard-content">
      <div>
        <h1>{title}</h1>
      </div>

      <Form className="flex-space-between">
        <InputText
          name="search"
          handleChange={item => setSearch(item)}
          classlist="input search-input"
          placeholder="Busque por nome da empresa ou CNPJ"
        />
        <Select
          name="segment_id"
          handleChange={item => setSegment(item)}
          placeholder="Filtre por segmento"
          options={segments}
        />
      </Form>

      <div class="mb-100">
        <table>
          <thead>
            <tr>
              <th>Nome da empresa</th>
              <th>Segmento</th>
              <th>CNPJ</th>
              <th>Usuários</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {resultados.map((data, index) => (
              <tr key={index}>
                <td>{data.name}</td>
                <td>{data.segment}</td>
                <td>{masks.cnpj.regx(data.cnpj)}</td>
                <td>{data.users ? data.users : 0}</td>
                <td className="text-right">
                  {/* <Link to="/empresas-associadas/exibir">
                    <button className="link-button">
                      <FontAwesomeIcon icon={faEye} />
                    </button>
                  </Link> */}
                  <Link to={`${location.pathname}/exibir/${data.id}`}>
                    <button className="link-button">
                      <FontAwesomeIcon icon={faPencilAlt} />
                    </button>
                  </Link>
                  <button onClick={() => handleDelete(data.id)} className="link-button">
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="self-flex-end float-button">
        <Link to={`${location.pathname}/novo`}>
          <button id="button">
            Adicionar Empresa {type == 1 ? 'Associada' : 'Apoiadora'}
            <FontAwesomeIcon icon={faPlusCircle} />
          </button>
        </Link>
      </div>
    </section>
  );
};

export default CompanyList;
