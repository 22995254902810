import React from 'react';

import {
  CompanyList,
  Footer,
  Header,
  Nav,
  Sidebar,
} from '../../../components/dashboard';

import '../../../style.css';

const ListPage = () => {
  document.title = 'InPACTO | Apoiadores';

  return (
    <>
      <Header />

      <section id="container">
        <Sidebar active="configuracoes" />

        <div id="content-wrapper">
          <Nav type="configuracoes" active="apoiadores" />
          <CompanyList title="Empresas Apoiadoras" type="2" />
          <Footer />
        </div>
      </section>
    </>
  );
};

export default ListPage;
