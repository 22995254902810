import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';

import {
  enablePatches,
  enableES5,
  enableMapSet,
  enableAllPlugins,
  setAutoFreeze,
} from 'immer';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import './config/ReactotronConfig';
import CookieConsent from 'react-cookie-consent';

import Routes from './routes';
import history from './services/history';

import { store, persistor } from './store';

import GlobalStyle from './styles/global';

enablePatches();
enableES5();
enableMapSet();
enableAllPlugins();
setAutoFreeze();

function App() {
  return (
    <>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Router history={history}>
            <GlobalStyle />
            <Routes />
            <CookieConsent
              location="bottom"
              buttonText="ACEITAR COOKIES"
              cookieName="inpacto"
              style={{ background: '#0075b1' }}
              buttonStyle={{
                backgroundColor: '#f05a22',
                color: '#FFF',
                fontWeight: 'Bold',
                fontSize: '13px',
              }}
              expires={150}
            >
              Usamos cookies para lhe oferecer uma experiência de navegação
              melhor. Para continuar navegando clique no botão "aceitar
              cookies". Para mais informações acesse nossa{' '}
              <a style={{ color: '#f05a22' }} href="">
                política de privacidade
              </a>
              .
            </CookieConsent>
            <ToastContainer
              toastClassName="toast-full-container"
              autoClose={3000}
              draggable
              hideProgressBar
            />
          </Router>
        </PersistGate>
      </Provider>
    </>
  );
}

export default App;
