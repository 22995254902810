import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { createStore, compose, applyMiddleware } from 'redux';

import { enablePatches, enableES5, enableMapSet, enableAllPlugins, setAutoFreeze } from 'immer';

export default (reducers, middlewares) => {
  enablePatches();
  enableES5();
  enableMapSet();
  enableAllPlugins();
  setAutoFreeze();

  const enhancer =
    process.env.NODE_ENV === 'development'
      ? compose(console.tron.createEnhancer(), applyMiddleware(...middlewares))
      : applyMiddleware(...middlewares);

  return createStore(reducers, enhancer);
};
