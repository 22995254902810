import React, { useEffect, useState, useRef } from 'react';

import { PowerBIEmbed } from 'powerbi-client-react';
import { Footer, Header, Sidebar } from '../../components/dashboard';

import api from '~/services/api';
import '../../style.css';
import { useSelector } from 'react-redux';

const DashboardPage = () => {
  document.title = 'InPACTO | Dashboard';
  const [cities, setCities] = useState([]);
  const [token, setToken] = useState(null);
  const id = '152458c5-cdc5-4fc9-bea4-ea276a65c065';

  const profile = useSelector(state => state.user.profile);
  useEffect(() => {
    const getToken = async () => {
      try {
        const response = await api.get(`bi?id=${id}`);
        if (response.data) {
          setToken(response.data.token);
        }
      } catch (error) {
        console.error(error);
      }
    };
    getToken();
    const getData = async () => {
      try {
        const response = await api.get('city/ibge');
        // console.log(response.data);
        if (response.data) {
          setCities(response.data.map(item => item.ibge_id));
        }
      } catch (error) {
        // console.error(error);
      }
    };
    getData();
  }, []);

  const report = useRef();
  let report2;
  return (
    <>
      <Header logo="/images/logo-empresa.png" />

      <section id="container">
        <Sidebar active="dashboards" />

        <div id="content-wrapper">
          <PowerBIEmbed
            ref={report}
            embedConfig={{
              type: 'report',
              tokenType: 1,

              accessToken: token,
              embedUrl: `https://app.powerbi.com/reportEmbed?reportId=152458c5-cdc5-4fc9-bea4-ea276a65c065&groupId=6c47c3a3-11f1-4354-8e6f-fd531834a005`, //&regras=Empresa&user=${profile.company_id}
              id,
              settings: {
                panes: {
                  pageNavigation: {
                    visible: false,
                  },
                  filters: {
                    expanded: false,
                    visible: false,
                  },
                },
              },
            }}
            eventHandlers={
              new Map([
                [
                  'loaded',
                  function(e) {
                    if (profile && profile.company_id) {
                      const filter = [
                        {
                          $schema: 'http://powerbi.com/product/schema#basic',
                          target: {
                            table: 'dim_Empresa_id',
                            column: 'id',
                          },
                          operator: 'In',
                          values: [profile.company_id],
                          // values: [profile.company_id],
                        },
                      ];
                      try {
                        report2.setFilters(filter);
                      } catch (errors) {
                        // console.log(errors);
                      }
                    }
                  },
                ],
              ])
            }
            cssClassName={'report-style-class'}
            getEmbeddedComponent={embeddedReport => {
              report2 = embeddedReport;
            }}
          />
          {/* <Footer /> */}
        </div>
      </section>
    </>
  );
};

export default DashboardPage;
