import React from 'react';

import {
  FormCompany,
  Footer,
  Header,
  Nav,
  Sidebar,
} from '../../../components/dashboard';

import '../../../style.css';

const CreatePage = () => {
  document.title = 'InPACTO | Novo Apoiador';

  return (
    <>
      <Header />

      <section id="container">
        <Sidebar active="apoiadores" />

        <div id="content-wrapper">
          <Nav type="configuracoes" active="apoiadores" />
          <FormCompany
            type="2"
            isAdd={true}
            title={'Adicionar Empresa Apoiadora'}
          />
          <Footer />
        </div>
      </section>
    </>
  );
};

export default CreatePage;
