import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { enablePatches, enableES5, enableMapSet, enableAllPlugins, setAutoFreeze } from 'immer';
import Wrapper from './Wrapper';

enablePatches();
enableES5();
enableMapSet();
enableAllPlugins();
setAutoFreeze();

ReactDOM.render(<Wrapper />, document.getElementById('root'));
