import * as yup from 'yup';
import moment from '~/config/moment';

const invalidDate = new Date('');

function parseDateFromFormats(formats, parseStrict) {
  return this.transform(function(value, originalValue) {
    value = moment(originalValue, formats, parseStrict);

    return value.isValid() ? value.toDate() : invalidDate;
  });
}
yup.setLocale({
  mixed: {
    required: 'Preencha esse campo para continuar',
  },
  string: {
    email: 'Preencha um e-mail válido',
    min: 'Valor muito curto (mínimo ${min} caracteres)',
    max: 'Valor muito longo (máximo ${max} caracteres)',
  },
  number: {
    min: 'Valor inválido (deve ser maior ou igual a ${min})',
    max: 'Valor inválido (deve ser menor ou igual a ${max})',
  },
});
// `addMethod` doesn't do anything special; it's
// equivalent to: yup.date.prototype.format = parseDateFromFormats
yup.addMethod(yup.string, 'format', parseDateFromFormats);
export default yup;
