import React from 'react';

import { toast } from 'react-toastify';
import './styles.css';

import { useLocation } from 'react-router-dom';

import { forgot } from '~/models/auth';
const VerifiqueSeuEmail = () => {
  const params = useLocation();

  const handleEmail = async e => {
    e.preventDefault();
    // console.log('params', params);
    await forgot(params && params.state && params.state.email);
    toast.success('Email enviado com sucesso ');
  };
  return (
    <section id="verifique-email">
      <div className="page-wrapper center">
        <h1>Verifique seu e-mail</h1>

        <p>
          Enviamos um e-mail para{' '}
          {params && params.state && params.state.email ? (
            <strong>{params.state.email}</strong>
          ) : (
            `você`
          )}
          . Clique no link do e-mail para redefinir sua senha.
        </p>
        <p>
          Caso não consiga ver o e-mail, verifique outros lugares onde ele possa
          estar, como sua pasta de lixo eletrônico, spam, social ou outras.
        </p>

        <button className="button gray-button" onClick={handleEmail}>
          Não recebi o e-mail
        </button>
      </div>
    </section>
  );
};

export default VerifiqueSeuEmail;
