import React from "react";

import { Header, Rodape, Login } from "../../components/sections";

import "../../style.css";

const LoginPage = () => {
  document.title = "InPACTO | Login";

  return (
    <div id="login-page-wrapper">
      <Header empty={true} />
      <Login />
      <Rodape />
    </div>
  );
};

export default LoginPage;
