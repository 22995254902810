import React, { useRef, useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faCheck, faRedo, faPlus } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { validateCnpj, validateCpf } from '~/utils/validate';
import { Form } from '@unform/web';
import '~/components/dashboard/Content/base.css';
import history from '~/services/history';
import masks from '~/services/masks';
import Yup from '~/config/yup';

import InputText from '~/components/form/InputText';
import MultiSelect from '~/components/form/MultiSelect';
import Select from '~/components/form/Select';
import BackButton from '~/components/elements/BackButton';
import Modal from '~/components/elements/Modal';
import { UserList } from '~/components/dashboard';

import { getById, remove, save } from '~/models/companies';
import { getAll } from '~/models/segment';
import * as uf from '~/models/uf';
import * as city from '~/models/city';
// import city from '~/models/city';

const FormCompany = ({ allowDelete = false, hideBack = false, id, type, title, showUsers = false, isAdd = false }) => {
  const [regions, setRegions] = useState([{ cities: [], selected: [] }]);
  const [name, setName] = useState();
  const [fantasyName, setFantasyName] = useState();
  const [segment, setSegment] = useState();
  const [ufs, setUfs] = useState([]);
  const [cnpj, setCnpj] = useState();
  const [segments, setSegments] = useState([]);
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userBirthday, setUserBirthday] = useState('');
  const [userCpf, setUserCpf] = useState('');
  const [disabled, setDisabled] = useState(false);
  const getData = async () => {
    if (!isAdd) {
      const obj = await getById(id);
      if (obj.name) {
        setName(obj.name);
      }
      if (obj.fantasy_name) {
        setFantasyName(obj.fantasy_name);
      }
      if (obj.segment_id) {
        setSegment(obj.segment_id);
      }
      if (obj.cnpj) {
        setCnpj(obj.cnpj);
      }
      if (obj.cnpj) {
        setUsers(obj.users);
      }
      const r = Object.values(obj.regions);
      // console.log('RR', r);
      if (r && r.length > 0) {
        setRegions(r);
      }
    }
    setSegments(await getAll());
    setUfs(await uf.getAll());
  };
  useEffect(() => {
    getData();
  }, [id]);

  const handleDelete = async e => {
    e.preventDefault();
    await remove(id);
    history.back();
  };

  const [users, setUsers] = useState([]);
  const [isChanged, setIsChanged] = useState(isAdd);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleChange = (key, value) => {
    setIsChanged(true);

    switch (key) {
      case 'name':
        return setName(value);
      case 'fantasyName':
        return setFantasyName(value);
      case 'segment':
        return setSegment(value);
      case 'cnpj':
        return setCnpj(value);
      case 'userName':
        return setUserName(value);
      case 'userEmail':
        return setUserEmail(value);
      case 'userBirthday':
        return setUserBirthday(value);
      case 'userCpf':
        return setUserCpf(value);
      default:
        return;
    }
  };

  const handleSubmit = async data => {
    if (!disabled) {
      try {
        setDisabled(true);
        // Remove all previous errors
        formRef.current.setErrors({});
        let shape = {
          name: Yup.string().required(),
          fantasy_name: Yup.string().required(),
          cnpj: Yup.string()
            .required()
            .test('is-cppj', 'Esse não é um cnpj válido', value => {
              return validateCnpj(masks.cnpj.remove(value));
            }),
          // city_id: Yup.array().test('is-cppj', 'Esse não é um cnpj válido', value => {
          //

          //   formRef.current.setFieldError('city_id[2]', 'Name is required');
          // }),
          segment_id: Yup.string().required(),
        };
        if (isAdd) {
          shape['user_name'] = Yup.string().required();
          shape['user_email'] = Yup.string()
            .email()
            .required();
          shape['user_birthday'] = Yup.string().required();
          shape['user_cpf'] = Yup.string()
            .required()
            .test('is-cpf', 'Esse não é um cpf válido', async value => {
              return await validateCpf(masks.cpf.remove(value));
            });
        }
        const schema = Yup.object().shape(shape);

        await schema.validate(data, {
          abortEarly: false,
        });
        // Validation passed
        //TODO: vfazer multi select chegar no data;

        if (await save(id, type, data)) {
          setIsChanged(false);

          setDisabled(false);
        }
      } catch (err) {
        setDisabled(false);
        const validationErrors = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message;
          });
          formRef.current.setErrors(validationErrors);
        }
      }
    }
    // await user.remove(user_id);
  };
  const removeItem = (e, key) => {
    e.preventDefault();

    setIsChanged(true);
    setRegions(regions.filter((i, k) => k !== key));
  };
  const handleAddRegion = () => {
    setIsChanged(true);
    //TODO:se algum tiver nulo, dai não adiciona e da um toast.
    setRegions([...regions, { cities: [], selected: [] }]);
  };
  // const onSearchCity = async (text, uf) => {
  //   if (!uf) {
  //     toast.warn('Selecione primeiro o estado');
  //     return false;
  //   }
  //   setRegions(
  //     regions.map(item => {
  //       if (item.uf_id === uf) {
  //         return {
  //           ...item,
  //           loading: true,
  //         };
  //       }
  //       return item;
  //     })
  //   );
  //   const cities = await city.get(text, uf);
  //   setRegions(
  //     regions.map(item => {
  //       if (item.uf_id === uf) {
  //         return {
  //           ...item,
  //           cities: cities,
  //           loading: false,
  //         };
  //       }
  //       return item;
  //     })
  //   );
  // };
  const handleCityChange = async (cities, region) => {
    setIsChanged(true);

    setRegions(
      regions.map((item, key) => {
        if (region === key) {
          return {
            ...item,
            selected: cities,
          };
        }
        return item;
      })
    );
  };
  const handleUfChange = async (uf, region) => {
    setIsChanged(true);

    setRegions(
      regions.map((item, key) => {
        if (region === key) {
          return {
            ...item,
            loading: true,
            cities: [],
            selected: [],
          };
        }
        return item;
      })
    );
    const cities = await city.get(null, uf);

    setRegions(
      regions.map((item, key) => {
        if (key === region) {
          return {
            ...item,
            cities: cities,
            selected: [],
            uf_id: uf,
            loading: false,
          };
        }
        return item;
      })
    );
  };
  const handleBack = e => {
    e.preventDefault();

    getData();
    // history.back();
  };

  const formRef = useRef(null);
  return (
    <section id="dashboard-content">
      <Form ref={formRef} onSubmit={handleSubmit}>
        <div>
          <div className="row flex-space-between ">
            <h1 className="flex-align-center">
              {!hideBack && <BackButton />}
              {title}
            </h1>
            {allowDelete && (
              <button onClick={handleDelete} id="page-action" className="text-right orange-text">
                Excluir empresa
                <FontAwesomeIcon icon={faTrash} />
              </button>
            )}
          </div>

          <div className="row">
            <InputText
              name={'name'}
              label="Nome da empresa"
              placeholder="Digite o nome da empresa"
              value={name}
              mandatory={true}
              handleChange={e => handleChange('name', e)}
            />
            <InputText
              label="Nome Fantasia"
              placeholder="Digite o nome fantasia"
              value={fantasyName}
              mandatory={true}
              name={'fantasy_name'}
              handleChange={e => handleChange('fantasyName', e)}
            />
          </div>
          <div className="row">
            <Select
              label="Segmento"
              options={segments}
              placeholder="Selecione um segmento"
              value={segment}
              mandatory={true}
              name={'segment_id'}
              handleChange={e => handleChange('segment', e)}
            />
            <InputText
              label="CNPJ"
              placeholder="Digite o CNPJ da empresa"
              mask="cnpj"
              mandatory={true}
              value={cnpj}
              name={'cnpj'}
              handleChange={e => handleChange('cnpj', e)}
            />
          </div>
        </div>

        <span className="divider"></span>
        {/* {JSON.stringify(regions)} */}
        <div className="row flex-space-between ">
          <h1 className="flex-align-center">Região de Influência</h1>
        </div>
        {regions.map((item, key) => {
          return (
            <div className="row">
              <div>
                <Select
                  label="Estado"
                  options={ufs.filter(uf => {
                    return uf.id === item.uf_id || !regions.some(region => region.uf_id === uf.id);
                  })}
                  placeholder="Selecione um estado"
                  value={item.uf_id}
                  mandatory={true}
                  name={`uf_id[${key}]`}
                  handleChange={e => handleUfChange(e, key)}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  alignContent: 'flex-start',
                }}
              >
                <MultiSelect
                  style={{
                    flex: 0.5,
                  }}
                  name={`city_id[${key}]`}
                  onSearch={text => {
                    //setbusy
                    // onSearchCity(text, item.uf_id);
                  }}
                  onChange={e => {
                    handleCityChange(e, key);
                  }}
                  defaultValue={item.cities}
                  busy={item.loading}
                  label="Cidades"
                  data={item.cities}
                  value={item.selected}
                />
                <button onClick={e => removeItem(e, key)}>
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </div>
            </div>
          );
        })}
        <p onClick={handleAddRegion} id="bottom-page-action" className="orange-text">
          Adicionar Região
          <FontAwesomeIcon icon={faPlus} />
        </p>

        <span className="divider"></span>
        {isAdd && (
          <>
            <div className="row">
              <InputText
                mandatory={true}
                name="user_name"
                label="Usuário principal"
                placeholder="Digite o nome"
                value={userName}
                handleChange={e => handleChange('userName', e)}
              />
              <InputText
                name="user_email"
                mandatory={true}
                label="E-mail do usuário"
                placeholder="Digite o e-mail"
                value={userEmail}
                handleChange={e => handleChange('userEmail', e)}
              />
            </div>
            <div className="row">
              <InputText
                name="user_birthday"
                mandatory={true}
                label="Data de nascimento"
                placeholder="00/00/0000"
                mask="date"
                value={userBirthday}
                handleChange={e => handleChange('userBirthday', e)}
              />
              <InputText
                name="user_cpf"
                mandatory={true}
                label="CPF"
                placeholder="Digite o CPF do usuário"
                mask="cpf"
                value={userCpf}
                handleChange={e => handleChange('userCpf', e)}
              />
            </div>

            <span className="divider" />

            <p>
              No primeiro acesso, como padrão, a senha do usuário principal da conta será os números do seu aniversário, com
              oito dígitos.
            </p>
          </>
        )}
        {showUsers && <UserList onRefresh={getData} users={users} />}
        {isChanged && (
          <div className="is-flex direction-row-reverse">
            <button type="submit" id="button">
              Salvar e finalizar
              <FontAwesomeIcon icon={faCheck} />
            </button>{' '}
            <button onClick={e => handleBack(e)} id="gray-button">
              Descartar alterações
              <FontAwesomeIcon icon={faRedo} flip="horizontal" />
            </button>
          </div>
        )}
      </Form>
    </section>
  );
};

export default FormCompany;
