import React from 'react';

import {
  FormCompany,
  Footer,
  Header,
  Nav,
  Sidebar,
} from '../../components/dashboard';

import '../../style.css';

const EmpresaPage = () => {
  document.title = 'InPACTO | Sua Empresa';

  return (
    <>
      <Header logo="/images/logo-empresa.png" />

      <section id="container">
        <Sidebar active="configuracoes" />

        <div id="content-wrapper">
          <Nav type="empresa" active="empresa" />
          <FormCompany
            hideBack={true}
            allowDelete={false}
            title={'Dados da Sua Empresa'}
          />
          <Footer />
        </div>
      </section>
    </>
  );
};

export default EmpresaPage;
