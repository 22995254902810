import produce, { enableAllPlugins } from 'immer';
enableAllPlugins();
const INITIAL_STATE = { list: [] };

export default function companies(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@companies/GET_ALL_SUCCESS': {
        draft.list = [...action.payload];
        break;
      }
      default:
    }
  });
}
