import React from "react";

import "./styles.css";

const Modal = (props) => {
  let classlist = "";

  const onClose = () => {
    if (props.onClose) {
      props.onClose();
    } else {
      props.toggle();
    }
  };

  if (props.isSmall === true) {
    classlist = "small";
  }

  if (props.isExtraSmall === true) {
    classlist = "extra-small";
  }

  if (props.open === false) {
    return "";
  }
  if (props.noButton === false) {
    return (
      <>
        <div id="modal-shadow"></div>
        <div id="modal" className={classlist}>
          <div id="actions">
            <button className=" toggle-button" onClick={() => props.toggle()}>
              <img alt="fechar modal" src="/images/icon-fechar-modal.png" />
            </button>
          </div>
          <div className="content">{props.children}</div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div id="modal-shadow" onClick={() => onClose()}></div>
        <div id="modal" className={classlist}>
          <div className="content">{props.children}</div>
        </div>
      </>
    );
  }
};

export default Modal;
