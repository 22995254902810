import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { store } from '~/store';

export default function RouteWrapper({ component: Component, isFirst = false, isPrivate, isAdmin, isPublic = false, ...rest }) {
  const { signed } = store.getState().auth;
  const { profile } = store.getState().user;

  console.log('routes', !isPublic && !signed && isPrivate);
  if (!isPublic && !signed && isPrivate) {
    return <Redirect to="/login" />;
  }
  // }
  // if (isFirst && signed && profile.first == 1) {
  //   return <Redirect to="/primeiro-acesso" />;
  // }

  // }
  if (!isPublic && signed && profile.type_id != 1 && isAdmin) {
    return <Redirect to="/dashboard" />;
  }
  if (!isPublic && signed && !isPrivate) {
    return <Redirect to="/dashboard" />;
  }
  if (!isPublic && !signed) {
    return <Redirect to="/" />;
  }

  return <Route {...rest} render={props => <Component {...props} />} />;
}
