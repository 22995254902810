import React, { useState, useEffect } from 'react';

import { PowerBIEmbed } from 'powerbi-client-react';
import api from '~/services/api';
import './styles.css';

const MapaSection = ({ onContactClick, onSupportClick }) => {
  const [token, setToken] = useState(null);
  const id = 'b867d38a-b16f-4e65-a3b9-e056531cee99';
  useEffect(() => {
    const getToken = async () => {
      try {
        const response = await api.get(`bi?id=${id}`);
        if (response.data) {
          setToken(response.data.token);
        }
      } catch (error) {
        console.error(error);
      }
    };
    getToken();
  }, []);
  return (
    <section id="mapa" name="mapa">
      <div id="imagem">
        {/* {token && ( */}
        <PowerBIEmbed
          embedConfig={{
            type: 'report',
            tokenType: 1,
            accessToken: token,
            embedUrl:
              'https://app.powerbi.com/reportEmbed?reportId=b867d38a-b16f-4e65-a3b9-e056531cee99&groupId=6c47c3a3-11f1-4354-8e6f-fd531834a005&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUJSQVpJTC1TT1VUSC1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d',
            id,
            permissions: 7,
            settings: {
              panes: {
                filters: { visible: false, expanded: false },
                pageNavigation: { visible: false },
              },
            },
          }}
          cssClassName={'report-style-class'}
        />
        {/* )} */}
      </div>
      <div id="texto">
        <p id="pre-title">Conheça o</p>
        <h2 className="orange-text">Índice de Vulnerabilidade InPACTO</h2>

        <>
          <hr />

          <p>
            <span class="is-visible-mobile">
              Pressione os estados e veja os munícipios que fazem parte do índice. Para acessar os dashboards faça login pelo
              desktop.
            </span>
            <span className="is-visible-desktop">
              Passe o mouse sobre os estados e veja os munícipios que fazem parte do índice.
            </span>
            Se quiser saber mais:
          </p>
          <button onClick={onContactClick} className="button btn-primary">
            Entre em contato conosco
          </button>
          <button onClick={onSupportClick} className="button btnGray">
            Associe-se ao INPACTO
          </button>
        </>
      </div>
    </section>
  );
};

export default MapaSection;
