/* eslint-disable no-useless-catch */

import array from '~/utils/array';
import api from '~/services/api';
import { toast } from 'react-toastify';
import masks from '~/services/masks';

const getUser = async (type = 'company') => {
  try {
    const response = await api.get(`user/${type}`).catch(function(error) {
      toast.error(error.message);
    });
    if (!response || !response.status || response.status !== 200) {
      toast.error(response);

      return [];
    } else {
      return response.data;
    }
  } catch (error) {
    toast.error(error);
    return [];
    //toasty
  }
};
const checkToken = async token => {
  try {
    const response = await api.get(`user/checktoken?token=${token}`).catch(function(error) {
      // if (!error.status) {
      toast.error(error.message);
      //toasty
      // }
    });
    if (!response || !response.status || response.status !== 200) {
      toast.error(response);
      // if
      return false;
    } else {
      // console.log('response', !response.data.success);
      if (!response.data.success) {
        toast.error('Token invalido');
      }
    }
  } catch (error) {
    toast.error(error);
    return false;
    //toasty
  }
};
const updatePassword = async password => {
  try {
    const response = await api.post(`user/updatepassword`, { password }).catch(function(error) {
      // if (!error.status) {
      toast.error(error.message);
      //toasty
      // }
      return {};
    });
    if (!response || !response.status || response.status !== 200) {
      toast.error(response);

      return {};
    } else {
      return response.data;
    }
  } catch (error) {
    toast.error(error);
    return {};
    //toasty
  }
};
const newPassword = async (token, password) => {
  try {
    const response = await api.post(`user/newpassword`, { token, password }).catch(function(error) {
      // if (!error.status) {
      toast.error(error.message);
      //toasty
      // }
      return {};
    });
    if (!response || !response.status || response.status !== 200) {
      toast.error(response);

      return {};
    } else {
      return response.data;
    }
  } catch (error) {
    toast.error(error);
    return {};
    //toasty
  }
};
const setPrincipal = async id => {
  try {
    const response = await api.get(`user/principal?id=${id}`).catch(function(error) {
      // if (!error.status) {
      toast.error(error.message);
      //toasty
      // }
    });
    if (!response || !response.status || response.status !== 200) {
      toast.error(response);

      return [];
    } else {
      return response.data;
    }
  } catch (error) {
    toast.error(error);
    return [];
    //toasty
  }
};
const save = async (id, data) => {
  try {
    const response = await api
      .post(`user/save${id ? `/${id}` : ''}`, {
        ...data,
        cpf: data.cpf && masks.cpf.remove(data.cpf),
        birthday: data.birthday && masks.date.remove(data.birthday),
        // admin: admin,
      })
      .catch(function(error) {
        toast.error(error.message);
      });
    if (!response || !response.status || response.status !== 200) {
      toast.error(response);
      return false;
    } else {
      if (!response.data.success) {
        toast.error(response.data.message);
        return false;
      } else {
        if (!id) {
          toast.success('Usuário criado com sucesso ');
        } else {
          toast.success('Usuário alterado com sucesso ');
        }
      }

      return true;
    }
  } catch (error) {
    toast.error(error);
    return false;
    //toasty
  }
};
const getById = async id => {
  try {
    //TODO: COLOCAR O PARAMETRO.
    // const response = await api.get(`company`).catch(function(error) {
    const response = await api.get(`user?id=${id}`).catch(function(error) {
      toast.error(error.message);
      return {};
    });
    if (response.status !== 200) {
      toast.error(response);
      return {};
    } else {
      if (response && response.data) {
        return {
          ...response.data,
          birthday: masks.date.regx(response.data.birthday),
        };
      }
      toast.error(response);
    }
  } catch (error) {
    toast.error(error.message);
    return {};
    //toasty
  }
};
const remove = async id => {
  try {
    const response = await api.delete(`user?id=${id}`, {}).catch(function(error) {
      // if (!error.status) {
      toast.error(error.message);
      //toasty
      // }
    });
    if (!response || !response.status || response.status !== 200) {
      toast.error(response);

      return false;
    } else {
      toast.success('Usuário excluida com sucesso ');
    }
  } catch (error) {
    toast.error(error);
    return false;
    //toastys
  }
};

export { getById, setPrincipal, remove, save, getUser, newPassword, checkToken, updatePassword };
