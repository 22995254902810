import api from '~/services/api';

import { toast } from 'react-toastify';
const getAll = async () => {
  try {
    const response = await api.post('uf').catch(function(error) {
      if (!error.status) {
        // console.log('error', error);
        toast.error(error);
        //toasty
      }
    });
    if (response.status !== 200) {
      toast.error(response);
      // console.log('error', response);
      return [];
    } else {
      return response.data.map(item => ({
        name: item.text,
        id: item.id,
      }));
    }
  } catch (error) {
    // console.log('error', error);
    toast.error(error);
    return [];
    //toasty
  }
};
export { getAll };
