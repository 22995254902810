import React, { useRef, useEffect } from 'react';

import Label from '../Label';
import { useField } from '@unform/core';

import './styles.css';

const InputText = props => {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(props.name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref, value) {
        ref.setInputValue(value);
      },
      clearValue(ref) {
        ref.setInputValue('');
      },
    });
  }, [fieldName, registerField]);
  let classlist = 'dashboard-input-textarea ' + props.classlist;

  let wrapperClasslist = '';

  if (props.invalid === true) {
    wrapperClasslist = 'invalid-input';
  }
  const handleChange = event => {
    // setValue(event.target.value);
    // console.log(event.target.value);
    props.handleChange(event.target.value);
  };
  return (
    <div className={wrapperClasslist}>
      <Label text={props.label} labelhtml={props.labelhtml} mandatory={props.mandatory} />
      <textarea
        ref={inputRef}
        defaultValue={defaultValue}
        className={classlist}
        placeholder={props.placeholder}
        onChange={handleChange}
        rows="4"
      >
        {props.value}
      </textarea>
      {props.invalid === true ? <p>{props.errorMessage}</p> : ''}
    </div>
  );
};

export default InputText;
