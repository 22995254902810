import React from 'react';

import {
  Footer,
  Header,
  Nav,
  Sidebar,
  FormUser,
} from '../../components/dashboard';

import '../../style.css';

const NovoUsuarioPage = () => {
  document.title = 'InPACTO | Novo Usuário';

  return (
    <>
      <Header logo="/images/logo-empresa.png" />

      <section id="container">
        <Sidebar active="configuracoes" />

        <div id="content-wrapper">
          <Nav type="empresa" active="usuarios" />
          <FormUser isAdd={true} title="Adicionar usuário" />
          <Footer />
        </div>
      </section>
    </>
  );
};

export default NovoUsuarioPage;
