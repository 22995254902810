import React, { useState, useRef } from 'react';

// import { Container } from './styles';

import InputText from '../../form/InputText';
import Modal from '../../elements/Modal';
import { Form } from '@unform/web';
import { send } from '~/models/contact';
import Yup from '~/config/yup';
import masks from '~/services/masks';
import { useEffect } from 'react';

function ModalSupporter({ open = false, onClose }) {
  const [isModalOpen, setIsModalOpen] = useState(open);
  const [novoNome, setNovoNome] = useState('');
  const [novoEmail, setNovoEmail] = useState('');
  const [novoTelefone, setNovoTelefone] = useState('');
  const [novoCelular, setNovoCelular] = useState('');
  const formRef = useRef(null);
  useEffect(() => {
    setIsModalOpen(open);
  }, [open]);

  const handleSubmit = async data => {
    try {
      // Remove all previous errors
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        name: Yup.string().required(),
        email: Yup.string()
          .email()
          .required(),
      });

      await schema.validate(data, {
        abortEarly: false,
      });
      // Validation passed
      if (
        await send({
          ...data,
          type_id: 2,
          telephone: masks.telephone.remove(data.telephone),
          mobile: masks.telephone.remove(data.mobile),
        })
      ) {
        setNovoNome('');
        setNovoEmail('');
        setNovoTelefone('');
        setNovoCelular('');
      }
    } catch (err) {
      // console.log('catch', err);
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
    // await user.remove(user_id);
  };

  const handleToogle = () => {
    setIsModalOpen(!isModalOpen);
    onClose && onClose();
  };
  return (
    <>
      <Modal open={isModalOpen} toggle={handleToogle} noButton={false}>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <div className="row">
            <div className="is-full">
              <h2>Seja um Associado</h2>
            </div>
          </div>

          <div className="row">
            <div class="is-half">
              <InputText
                name="name"
                label="Nome"
                placeholder="Digite seu nome"
                value={novoNome}
                handleChange={e => setNovoNome(e)}
              />
            </div>
            <div class="is-half">
              <InputText
                name="email"
                label="E-mail"
                placeholder="Digite seu e-mail"
                value={novoEmail}
                handleChange={e => setNovoEmail(e)}
              />
            </div>
          </div>
          <div className="row">
            <div className="is-half">
              <InputText
                mask={'telephone'}
                name="telephone"
                label="Telefone"
                placeholder="Número do seu telefone"
                value={novoTelefone}
                handleChange={e => setNovoTelefone(e)}
              />
            </div>
            <div className="is-half">
              <InputText
                mask={'telephone'}
                name="mobile"
                label="Celular"
                placeholder="Número do seu celular"
                value={novoCelular}
                handleChange={e => setNovoCelular(e)}
              />
            </div>
          </div>
          <div className="row">
            <div className="is-half">
              <button className="button orange-button  btn-primary">Entre em contato</button>
            </div>
          </div>
          <div className="row">
            <div className="is-full">
              <small class="policy">
                Estamos comprometidos com sua privacidade. Não serão encaminhadas informações para seu e-mail e telefone sem sua
                autorização. Colaboradores do instituto podem contatar você para dar continuidade à solicitação.{' '}
                <a target="_blank" href="https://inpacto.org.br/politica-de-privacidade/">
                  Leia a Política de Privacidade
                </a>
                .
                <br />
                <br />
              </small>
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
}

export default ModalSupporter;
