import React, { useState } from 'react';

import {
  Apoiadores,
  Banner,
  Faq,
  Header,
  Mapa,
  Contato,
  Rodape,
} from '../../components/sections';

import '../../style.css';

const LandingPage = () => {
  document.title = 'InPACTO';

  const [contact, setContact] = useState(false);
  const [support, setSupport] = useState(false);
  return (
    <div id="landing-page-wrapper">
      <Header open={support} onClose={() => setSupport(false)} />
      <Banner />
      <Mapa
        onContactClick={() => setContact(true)}
        onSupportClick={() => setSupport(true)}
      />
      <Faq />
      <Apoiadores />
      <Contato open={contact} onClose={() => setContact(false)} />
      <Rodape />
    </div>
  );
};

export default LandingPage;
