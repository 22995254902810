import React from 'react';

import { Header, Rodape, VerifiqueSeuEmail } from '../../components/sections';

import '../../style.css';

const VerifiqueSeuEmailPage = () => {
  document.title = 'InPACTO | Verifique Seu E-mail';

  return (
    <div id="login-page-wrapper">
      <Header empty={true} />
      <VerifiqueSeuEmail />
      <Rodape />
    </div>
  );
};

export default VerifiqueSeuEmailPage;
