import api from '~/services/api';

import { toast } from 'react-toastify';
import masks from '~/services/masks';
import history from '~/services/history';

import axios from 'axios';
const { CancelToken } = axios;
let cancelList = null;
const getById = async id => {
  try {
    //TODO: COLOCAR O PARAMETRO.
    // const response = await api.get(`company`).catch(function(error) {
    const response = await api.get(`company?id=${id}`).catch(function(error) {
      toast.error(error.message);
      return {};
    });
    if (response.status !== 200) {
      toast.error(response);
      // console.log('error1', response);
      return {};
    } else {
      if (response && response.data) {
        return response.data;
      }
      toast.error(response);
      // console.log('error4', response);
    }
  } catch (error) {
    // console.log('error', error);

    toast.error(error.message);
    return {};
    //toasty
  }
};
const get = async filter => {
  try {
    cancelList && cancelList();
    const response = await api
      .post('company', filter, {
        cancelToken: new CancelToken(function executor(cancel) {
          // An executor function receives a cancel function as a parameter
          cancelList = cancel;
        }),
      })
      .catch(function(error) {
        if (!error.status) {
          // console.log('error', error);
          toast.error(error);
          //toasty
        }
      });
    if (response.status !== 200) {
      toast.error(response);
      // console.log('error', response);
      return [];
    } else {
      return response.data;
    }
  } catch (error) {
    // console.log('error', error);
    toast.error(error);
    return [];
    //toasty
  }
};
const remove = async id => {
  try {
    const response = await api.delete(`company?id=${id}`, {}).catch(function(error) {
      // if (!error.status) {
      console.tron.log('error2', error);
      toast.error(error.message);
      //toasty
      // }
    });
    if (!response || !response.status || response.status !== 200) {
      toast.error(response);
      // console.log('error', response);
      return false;
    } else {
      toast.success('Empresa excluida com sucesso ');
    }
  } catch (error) {
    console.tron.log('error1', error);
    toast.error(error);
    return false;
    //toasty
  }
};
const save = async (id, type, data) => {
  try {
    const response = await api
      .post(`company/save${id ? `/${id}` : ''}`, {
        ...data,
        type,
        region: data.city_id.map(region => region.map(city => city.id)),
        cnpj: masks.cnpj.remove(data.cnpj),
        user_cpf: data.user_cpf && masks.cpf.remove(data.user_cpf),
        user_birthday: data.user_birthday && masks.date.remove(data.user_birthday),
      })
      .catch(function(error, test) {
        toast.error(error.message);
        //toasty
        // }
      });
    if (!response || !response.status || response.status !== 200) {
      toast.error(response);
      // console.log('error', response);
      return false;
    } else {
      if (!response.data.success) {
        toast.error(response.data.message);
      } else {
        toast.success(' Salva as alterações ');
      }

      return true;
    }
  } catch (error) {
    // console.tron.log('error1', error);
    toast.error(error);
    return false;
    //toasty
  }
};
export { get, remove, getById, save };
