import React, { useState, useEffect } from 'react';
import { HashLink as Link } from 'react-router-hash-link';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-regular-svg-icons';

import { Rodape } from '../';
import ModalSupporter from '~/components/sections/ModalSupporter';

import './styles.css';

const Header = props => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => setIsOpen(!isOpen);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setIsModalOpen(props.open);
  }, [props.open]);
  const toggleModal = () => {
    props.onClose && props.onClose();
    setIsModalOpen(!isModalOpen);
  };

  return (
    <section id="header">
      <span id="logo-wrapper">
        <Link to="/">
          <img id="logo" alt="logo" src="/images/logo-inpacto.png" />
        </Link>
      </span>

      {props.empty !== true ? (
        <>
          <nav className="is-visible-desktop">
            <span className="menu-item">
              <Link smooth to="/#mapa">
                Índice de Vulnerabilidade InPACTO
              </Link>
            </span>
            <span className="menu-item">
              <Link smooth to="/#faq">
                Perguntas Frequentes
              </Link>
            </span>
            <span className="menu-item">
              <Link to="/login">
                <FontAwesomeIcon icon={faUser} />
                Acesse sua conta
              </Link>
            </span>
          </nav>

          <a href="https://inpacto.org.br/" target="_blank" className="is-visible-desktop btn-secondary" id="button">
            Conheça o InPACTO
          </a>

          <div id="mobile-menu" className={isOpen ? 'open' : ''}>
            <div id="mobile-menu-header">
              <span id="logo-wrapper">
                <img id="logo" alt="logo" src="/images/logo-inpacto-branco.png" />
              </span>
              <span>
                <img onClick={toggleMenu} alt="fechar menu" src="/images/icon-fechar-menu.png" />
              </span>
            </div>
            <div id="mobile-menu-content">
              <div>
                <span className="menu-item">
                  <Link onClick={toggleMenu} smooth to="/#mapa">
                    Índice de Vulnerabilidade InPACTO
                  </Link>
                </span>
                <div className="menu-separator"></div>
              </div>

              <div>
                <span className="menu-item">
                  <Link onClick={toggleMenu} smooth to="/#faq">
                    Perguntas Frequentes
                  </Link>
                </span>
                <div className="menu-separator"></div>
              </div>
              {/*
              <div>
                <span className="menu-item">
                  <Link to="/login" onClick={toggleMenu}>
                    Acesse sua conta
                  </Link>
                </span>
                <div className="menu-separator"></div>
              </div> */}
            </div>
            <Rodape />
          </div>

          <ModalSupporter onClose={toggleModal} open={isModalOpen} noButton={false} />
        </>
      ) : (
        ''
      )}
    </section>
  );
};

export default Header;
