import { createGlobalStyle } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';
import 'react-widgets/dist/css/react-widgets.css';

export default createGlobalStyle`

  .Toastify__toast--success{
    background: #0075b1;
  }
  .Toastify__toast--error{
    background: #f05a22;
  }
/* .Toastify__toast-container{

  &.Toastify__toast-container--bottom-center
  {
    width:100%;
    left:0;
    margin-left: 50%;
    padding: 0;
    bottom: 0;
    .toast-full-container
    {
      &.Toastify__toast{
        margin-bottom:0;
        min-height:44px;
        text-align:center;
      }
      .Toastify__close-button{
        margin:6px;
      }
      .Toastify__toast-body{
        font-weight:bold;
      }
    }
  }
} */
`;
