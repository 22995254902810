import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';

import Erro404Page from '../pages/site/Erro404Page';
import LandingPage from '../pages/site/LandingPage';
import LoginPage from '../pages/site/LoginPage';
import RecuperarSenhaPage from '../pages/site/RecuperarSenhaPage';
import VerifiqueSeuEmailPage from '../pages/site/VerifiqueSeuEmailPage';
import RedefinirSenhaPage from '../pages/site/RedefinirSenhaPage';
import SenhaRedefinidaPage from '../pages/site/SenhaRedefinidaPage';

import ApoiadoresListPage from '../pages/inpacto/apoiadores/ListPage';
import ApoiadoresShowPage from '../pages/inpacto/apoiadores/ShowPage';
import ApoiadoresCreatePage from '../pages/inpacto/apoiadores/CreatePage';

import AssociadasListPage from '../pages/inpacto/associadas/ListPage';
import AssociadasShowPage from '../pages/inpacto/associadas/ShowPage';
import AssociadasCreatePage from '../pages/inpacto/associadas/CreatePage';

import AdministradoresListPage from '../pages/inpacto/administradores/ListPage';
import AdministradoresShowPage from '../pages/inpacto/administradores/ShowPage';
import AdministradoresCreatePage from '../pages/inpacto/administradores/CreatePage';

import EmpresaPage from '../pages/empresa/EmpresaPage';
import UsuariosPage from '../pages/empresa/UsuariosPage';
import NovoUsuarioPage from '../pages/empresa/NovoUsuarioPage';
import EditarUsuarioPage from '../pages/empresa/EditarUsuarioPage';
import MinhaContaPage from '../pages/empresa/MinhaContaPage';

// import SelecaoDashboardPage from '../pages/dashboards/SelecaoDashboardPage';
import DashboardPage from '../pages/dashboards/DashboardPage';

export default function Routes() {
  return (
    <Switch>
      <Route isPublic exact path="/">
        <LandingPage />
      </Route>

      <Route isPrivate isAdmin exact path="/empresas-associadas/novo">
        <AssociadasCreatePage />
      </Route>
      <Route isPrivate isAdmin path="/empresas-associadas/exibir/:id">
        <AssociadasShowPage />
      </Route>
      <Route isPrivate isAdmin path="/empresas-associadas">
        <AssociadasListPage />
      </Route>

      <Route isPrivate isAdmin path="/empresas-apoiadoras/exibir/:id">
        <ApoiadoresShowPage />
      </Route>
      <Route isPrivate isAdmin exact path="/empresas-apoiadoras/novo">
        <ApoiadoresCreatePage />
      </Route>
      <Route isPrivate isAdmin exact path="/empresas-apoiadoras">
        <ApoiadoresListPage />
      </Route>
      <Route isPrivate isAdmin exact path="/administradores/novo">
        <AdministradoresCreatePage />
      </Route>
      <Route isPrivate isAdmin path="/administradores/exibir/:id">
        <AdministradoresShowPage />
      </Route>
      <Route isPrivate isAdmin exact path="/administradores">
        <AdministradoresListPage />
      </Route>
      {/* <Route isPrivate exact path="/selecao-dashboard">
        <SelecaoDashboardPage />
      </Route> */}
      <Route isPrivate exact path="/dashboard">
        <DashboardPage />
      </Route>
      <Route isPrivate exact path="/sua-empresa">
        <EmpresaPage />
      </Route>
      <Route isPrivate exact path="/usuarios">
        <UsuariosPage />
      </Route>
      <Route isPrivate exact path="/usuarios/novo">
        <NovoUsuarioPage />
      </Route>
      <Route isPrivate path="/editar-usuario/:id">
        <EditarUsuarioPage />
      </Route>
      <Route isPrivate exact path="/minha-conta">
        <MinhaContaPage />
      </Route>
      <Route isPublic exact path="/login">
        <LoginPage />
      </Route>
      <Route exact isPublic path="/esqueci-minha-senha">
        <RecuperarSenhaPage />
      </Route>
      <Route exact isPublic path="/verifique-seu-email">
        <VerifiqueSeuEmailPage />
      </Route>
      <Route exact isPublic path="/redefinir-senha/:token">
        <RedefinirSenhaPage />
      </Route>
      <Route isPrivate path="/primeiro-acesso">
        <RedefinirSenhaPage first />
      </Route>
      <Route exact path="/senha-redefinida">
        <SenhaRedefinidaPage />
      </Route>
      <Route isPublic path="*">
        <Erro404Page />
      </Route>
    </Switch>
  );
}
