import { takeLatest, call, put, all } from 'redux-saga/effects';

import * as companies from '~/models/companies';

import { getAllSuccess } from './actions';

export function* get({ payload }) {
  try {
    // const response = yield call(companies.getAll, payload.speciality);
    // yield put(getAllSuccess(response));
  } catch (err) {}
}

export default all([
  takeLatest('@companies/GET_ALL', get),
  takeLatest('persist/REHYDRATE', get),
]);
