import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import history from '~/services/history';
import './styles.css';

const BackButton = props => {
  const handleBack = e => {
    e.preventDefault();
    history.back();
  };
  return (
    // <Link to={props.url}>
    <button onClick={e => handleBack(e)} className="round-button">
      <FontAwesomeIcon icon={faArrowLeft} />
    </button>
    // </Link>
  );
};

export default BackButton;
