import React from "react";

import { Header, Rodape, SenhaRedefinida } from "../../components/sections";

import "../../style.css";

const SenhaRedefinidaPage = () => {
  document.title = "InPACTO | Senha Redefinida";

  return (
    <div id="login-page-wrapper">
      <Header empty={true} />
      <SenhaRedefinida />
      <Rodape />
    </div>
  );
};

export default SenhaRedefinidaPage;
