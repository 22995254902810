import axios from 'axios';
import { signOut } from '~/store/modules/auth/actions';
import { store } from '~/store';

console.log(`BASEURL ${process.env.REACT_APP_BACKEND_URL}`);
const api = axios.create({
  //
  baseURL: `${process.env.REACT_APP_BACKEND_URL}`,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': true,
    'Access-Control-Allow-Methods': 'Access-Control-Allow-Methods',
    'Access-Control-Allow-Headers':
      'access-control-allow-methods, access-control-allow-credentials, X-API-KEY, Origin, X-Requested-With, Content-Type, Accept, Access-Control-Request-Method, Access-Control-Allow-Headers',
    'Content-Type': 'multipart/form-data',
  },
  crossDomain: true,
  xDomain: true,
  xDomainRequest: true,
  preflightContinue: true,
  transformRequest: [
    function(data, headers) {
      let formart = new FormData();
      if (data) {
        Object.entries(data).map(([key, value]) => {
          formart.append(key, value);
        });
      }
      return formart;
    },
  ],
});
api.interceptors.request.use((config, data) => {
  // console.log('config', config, data);
  if (config.data instanceof FormData) {
    Object.assign(config.headers, config.data.getHeaders());
  }
  return config;
});
api.interceptors.response.use(
  response => response,
  error => {
    if (
      error &&
      (error.number === 401 ||
        error.code === 401 ||
        error.message === 'Request failed with status code 401')
    ) {
      if (store && store.dispatch) {
        // store.dispatch(signOut());
      }
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export default api;
