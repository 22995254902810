import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';
import history from '~/services/history';
import * as auth from '~/models/auth';
// import * as users from '~/models/users';

import { signInSuccess, signFailure } from './actions';

export function* signIn({ payload }) {
  try {
    const { email, password, keep } = payload;
    let user;
    let token;
    const response = yield call(auth.login, email, password, keep);

    if (response) {
      token = response.token;
      user = response.user;

      api.defaults.headers.Authorization = `Bearer ${token}`;

      yield put(signInSuccess(token, user));
    } else {
      toast.error('Falha na autenticação, verifique seus dados');
      return false;
      // yield put(signFailure());
    }
  } catch (err) {
    // console.log('err', err);
    toast.error('Falha na autenticação, verifique seus dados!!');
    return false;
    // yield put(signFailure());
  }
}

export function* signUp({ payload }) {
  // try {
  //   const { name, email, password } = payload;
  //   yield call(api.post, 'users', {
  //     name,
  //     email,
  //     password,
  //     provider: true,
  //   });
  //   history.push('/');
  // } catch (err) {
  //   toast.error('Falha no cadastro, verifique seus dados!');
  //   yield put(signFailure());
  // }
}

export function setToken({ payload }) {
  if (!payload) return;

  const { token } = payload.auth;

  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  }
}

export function signOut() {
  history.go('/');
}
export function success({ payload }) {
  const { user } = payload;

  if (user && user.first && user.first == '1') {
    // console.log('success 1', user);
    window.location = '../primeiro-acesso';
  } else {
    // console.log('success 2', user);
    window.location = '../dashboard';
  }
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/SIGN_IN_SUCCESS', success),
  takeLatest('@auth/SIGN_UP_REQUEST', signUp),
  takeLatest('@auth/SIGN_OUT', signOut),
]);
