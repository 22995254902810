import React from 'react';

import { Footer, Header, Nav, Sidebar, FormUser } from '../../../components/dashboard';

import '../../../style.css';

const CreatePage = () => {
  document.title = 'InPACTO | Novo Administrador';

  return (
    <>
      <Header />

      <section id="container">
        <Sidebar active="configuracoes" />

        <div id="content-wrapper">
          <Nav type="configuracoes" active="administradores" />
          <FormUser admin isAdd={true} title="Adicionar Administrador" />
          <Footer />
        </div>
      </section>
    </>
  );
};

export default CreatePage;
