import React from 'react';

import { FormUser, Footer, Header, Nav, Sidebar } from '../../components/dashboard';
import { useSelector } from 'react-redux';
import '../../style.css';

// import { useParams } from 'react-router-dom';
const MinhaContaPage = () => {
  document.title = 'InPACTO | Minha Conta';

  const user = useSelector(state => state.user.profile);
  return (
    <>
      <Header logo="/images/logo-empresa.png" />

      <section id="container">
        <Sidebar active="minha-conta" />

        <div id="content-wrapper">
          <Nav type="conta" />
          <FormUser isOwn showPhoto hideBack title={'Seus Dados'} id={user.id} />
          <Footer />
        </div>
      </section>
    </>
  );
};

export default MinhaContaPage;
