import moment from 'moment';

const masks = {
  height: { mask: '9.99' },
  weight: {
    mask: '?99.99',
  },
  pressure: { mask: '999X99' },
  circumference: { mask: '999' },
  telephone: {
    mask: '(99) 9999-99999',
    regx(value) {
      if (!value) return;
      return value.replace(/(\d{2})(\d{4})(\d{4})/, '( $1 ) $2 - $3');
    },
    regxEdit(value) {
      if (!value) return;
      return value.replace(/(\d{2})(\d{4})(\d{4})/, '($1)$2-$3');
    },
    remove(value) {
      if (!value) return;
      return value.replace(/[\(\)\.\s-]+/g, '');
    },
  },
  hour: {
    mask: '99:99',
    regx(value) {
      if (!value) return;
      return value.padStart(4, '0').replace(/(\d{2})(\d{2})/, ' $1:$2');
    },
  },
  date: {
    mask: '99/99/9999',
    regx(value) {
      return moment(value, 'YYYY-MM-DD').format('DD / MM / YYYY');
    },
    remove(value) {
      return moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD');
    },
  },
  rg: {
    mask: '99999999999999',
  },
  cpf: {
    mask: '999.999.999-99',
    regx(value) {
      if (!value) return '';
      return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, ' $1.$2.$3-$4');
    },
    remove(value) {
      if (!value) return '';
      return value.replace(/[^\d]+/g, '');
    },
  },
  cnpj: {
    mask: '99.999.999/9999-99',
    regx(value) {
      if (!value) return;
      return value.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        ' $1.$2.$3/$4-$5'
      );
    },
    remove(value) {
      if (!value) return;
      return value.replace(/[^\d]+/g, '');
    },
  },
  others: {
    mask: '',
  },
  gender(value) {
    switch (value) {
      case 'M':
        return 'Masculino';
        break;

      case 'F':
        return 'Feminino';
        break;
      default:
        return 'Outro';
        break;
    }
  },
};
export default masks;
