import React from 'react';

import {
  FormCompany,
  Footer,
  Header,
  Nav,
  Sidebar,
} from '../../../components/dashboard';

import '../../../style.css';

const CreatePage = () => {
  document.title = 'InPACTO | Nova Empresa Associada';

  return (
    <>
      <Header />

      <section id="container">
        <Sidebar active="configuracoes" />

        <div id="content-wrapper">
          <Nav type="configuracoes" active="associadas" />
          <FormCompany
            type="1"
            isAdd={true}
            title={'Adicionar Empresa Associada'}
          />
          <Footer />
        </div>
      </section>
    </>
  );
};

export default CreatePage;
