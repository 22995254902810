import React from "react";

import { Link } from "react-router-dom";

import "./styles.css";

const SenhaRedefinida = () => {
  return (
    <section id="senha-redefinida">
      <div className="page-wrapper center">
        <h1>Você alterou sua senha com sucesso</h1>

        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p>
        <p>
          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
          nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit.
        </p>
        <button className="button gray-button">
          <Link to="/login">Voltar para a tela de login</Link>
        </button>
      </div>
    </section>
  );
};

export default SenhaRedefinida;
