import React, { useState, useRef, useEffect } from 'react';

import InputText from '../../form/InputText';
import InputTextarea from '../../form/InputTextarea';
import Select from '../../form/Select';
import Modal from '../../elements/Modal';

import { Form } from '@unform/web';
import './styles.css';
import { send } from '~/models/contact';
import Yup from '~/config/yup';

const Contato = ({ open, onClose }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [contatoEnviado, setContatoEnviado] = useState(false);

  const [contatoNome, setContatoNome] = useState('');
  const [contatoEmail, setContatoEmail] = useState('');
  const [contatoMessagem, setContatoMessagem] = useState('');
  const [assuntoContato, setAssuntoContato] = useState();

  useEffect(() => {
    setIsModalOpen(open);
  }, [open]);
  const assuntos = [
    { id: 'Contato', name: 'Contato' },
    { id: 'Feedback', name: 'Feedback' },
    { id: 'Quero fazer parte', name: 'Quero fazer parte' },
  ];

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);

    onClose && onClose();
  };

  const continuarNavegando = () => {
    setContatoEnviado(false);
    toggleModal();
  };

  const formRef = useRef(null);

  const handleSubmit = async data => {
    try {
      // Remove all previous errors
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        name: Yup.string().required(),
        email: Yup.string()
          .email()
          .required(),
      });

      await schema.validate(data, {
        abortEarly: false,
      });
      // Validation passed
      if (
        await send(
          {
            ...data,
            type_id: 1,
          },
          false
        )
      ) {
        setContatoEnviado(true);

        setContatoNome('');
        setContatoEmail('');
        setContatoMessagem('');
        setAssuntoContato();
      }
    } catch (err) {
      // console.log('catch', err);
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
    // await user.remove(user_id);
  };

  return (
    <>
      <section id="contato">
        <div>
          <img alt="logo inpacto" src="/images/logo-inpacto.png" />
        </div>

        <div id="contato-text" className="text-left">
          <p>Avenida Paulista, 2.073, Conjunto Nacional</p>
          <p>Horsa 1, conjunto 321 - Consolação, São Paulo - SP</p>
          <p>CEP 01311-940 - Brasil</p>
          <p>Telefone: (11) 3179-0151</p>{' '}
          <p>
            <a target="_blank" href="https://inpacto.org.br/termos-de-uso/">
              Termos de uso
            </a>
          </p>
          <p>
            <a target="_blank" href="https://inpacto.org.br/politica-de-privacidade/">
              Política de Privacidade
            </a>
          </p>
        </div>

        <div className="text-center">
          <button className="btn-primary" onClick={() => toggleModal()} id="button">
            Fale com a gente
          </button>
        </div>
      </section>

      <Modal classlist="modal-contato" open={isModalOpen} toggle={toggleModal} isSmall={contatoEnviado} noButton={false}>
        {contatoEnviado === false ? (
          <>
            <div className="row">
              <div className="is-full">
                <h2>Entre em contato</h2>
              </div>
            </div>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <div className="row">
                <div className="is-half">
                  <InputText
                    name="name"
                    label="Nome"
                    mandatory={true}
                    placeholder="Digite seu nome"
                    value={contatoNome}
                    handleChange={e => setContatoNome(e)}
                  />
                </div>
                <div className="is-half">
                  <InputText
                    name="email"
                    label="E-mail"
                    mandatory={true}
                    placeholder="Digite seu e-mail"
                    value={contatoEmail}
                    handleChange={e => setContatoEmail(e)}
                  />
                </div>
              </div>
              <div className="row">
                <div className="is-half">
                  <Select
                    name="subject"
                    label="Assunto"
                    options={assuntos}
                    placeholder="Selecione um assunto"
                    value={assuntoContato}
                    handleChange={e => setAssuntoContato(e)}
                  />
                </div>
              </div>
              <div className="row">
                <div className="is-full">
                  <InputTextarea
                    name="message"
                    label="Sua mensagem"
                    placeholder="Escreva sua mensagem aqui"
                    value={contatoMessagem}
                    handleChange={e => setContatoMessagem(e)}
                  />
                </div>
              </div>
              <div className="row">
                <div className="is-half">
                  <button type="submit" className="button orange-button btn-primary">
                    Enviar
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="is-full">
                  <small class="policy">
                    Estamos comprometidos com sua privacidade. Não serão encaminhadas informações para seu e-mail e telefone sem
                    sua autorização. Colaboradores do instituto podem contatar você para dar continuidade à solicitação.{' '}
                    <a target="_blank" href="https://inpacto.org.br/politica-de-privacidade/">
                      Leia a Política de Privacidade
                    </a>
                    .
                    <br />
                    <br />
                  </small>
                </div>
              </div>
            </Form>
          </>
        ) : (
          <div className="text-center">
            <img className="contato-enviado-imagem" alt="contato enviado" src="/images/icon-contato-enviado.png" />
            <h2>Contato enviado com sucesso</h2>
            <button onClick={() => continuarNavegando()} className="button btn-primary">
              Continuar navegando
            </button>
          </div>
        )}
      </Modal>
    </>
  );
};

export default Contato;
