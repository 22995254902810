import React from 'react';

import './styles.css';

const Footer = () => {
  return (
    <section id="dashboard-footer">
      <span>
        InPACTO®2020 - Todos os direitos reservados | Criado e Desenvolvido por
        BriviaDez
      </span>
    </section>
  );
};

export default Footer;
