import React from 'react';
import { useParams } from 'react-router-dom';
import {
  FormCompany,
  Footer,
  Header,
  Nav,
  Sidebar,
} from '../../../components/dashboard';

import '../../../style.css';

const ShowPage = () => {
  const params = useParams();
  document.title = 'InPACTO | Exibir apoiador';

  return (
    <>
      <Header />

      <section id="container">
        <Sidebar active="configuracoes" />

        <div id="content-wrapper">
          <Nav type="configuracoes" active="apoiadores" />
          <FormCompany
            type={2}
            id={params.id}
            title={'Dados da Empresa Associada'}
            showUsers={true}
            allowDelete={true}
          />
          <Footer />
        </div>
      </section>
    </>
  );
};

export default ShowPage;
