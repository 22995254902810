import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import history from '~/services/history';
import * as users from '~/models/users';

import { updateProfileSuccess, updateProfileFailure } from './actions';

export function* first() {
  // console.log('FIRST');
  // history.go('/dashboard');
}
export function* updateProfile({ payload }) {
  try {
    const { id, ...data } = payload.profile;
    let response = yield call(users.save, id, data);
    if (response) {
      yield put(updateProfileSuccess(payload.profile));
    }
  } catch (err) {
    yield put(updateProfileFailure());
  }
}
export default all([takeLatest('@user/UPDATE_PROFILE_REQUEST', updateProfile), takeLatest('@user/UPDATE_FIRST', first)]);
