import React from 'react';

import { Header, Rodape, RedefinirSenha } from '../../components/sections';

import '../../style.css';

const RedefinirSenhaPage = ({ first = false }) => {
  document.title = 'InPACTO | Redefinir Senha';

  return (
    <div id="login-page-wrapper">
      <Header empty={true} />
      <RedefinirSenha first={first} />
      <Rodape />
    </div>
  );
};

export default RedefinirSenhaPage;
