import React from "react";

import "./styles.css";

const Rodape = () => {
  return (
    <section id="rodape">
      InPACTO®2020 - Todos os direitos reservados | Criado e Desenvolvido por
      BriviaDez
    </section>
  );
};

export default Rodape;
