import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import {
  faBuilding,
  faChild,
  faLink,
  faUserCog,
  faAward,
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

import './styles.css';

const Nav = props => {
  const [navContent, setNavContent] = useState({});
  const [active, setActive] = useState(props.active);
  const user = useSelector(state => state.user.profile);
  const content = {
    configuracoes: {
      title: 'Configurações',
      links: [
        {
          class: 'associadas',
          link: '/empresas-associadas',
          icon: faBuilding,
          text: 'Empresas Associadas',
        },
        {
          class: 'apoiadores',
          link: '/empresas-apoiadoras',
          icon: faAward,
          text: 'Apoiadores',
        },
        {
          class: 'administradores',
          link: '/administradores',
          icon: faUserCog,
          text: 'Administradores',
        },
      ],
    },

    conta: { title: 'Minha Conta', links: [] },

    dashboards: {
      title: 'Dashboards',
      links: [
        {
          class: 'inicio',
          link: '/selecao-dashboard',
          icon: faBuilding,
          text: 'Início',
        },
        {
          class: 'menu-1',
          link: '/dashboard',
          icon: faChild,
          text: 'Trabalho infantil',
        },
        {
          class: 'menu-2',
          link: '/dashboard',
          icon: faLink,
          text: 'Trabalho escravo',
        },
        {
          class: 'menu-3',
          link: '/dashboard',
          icon: faChild,
          text: 'Trabalho infantil',
        },
        {
          class: 'menu-4',
          link: '/dashboard',
          icon: faLink,
          text: 'Trabalho escravo',
        },
      ],
    },

    empresa: {
      title: 'Configurações',
      links: [
        {
          class: 'empresa',
          link: '/sua-empresa',
          icon: faBuilding,
          text: 'Sua Empresa',
        },
        {
          class: 'usuarios',
          link: '/usuarios',
          icon: faUserCog,
          text: 'Usuários',
        },
      ],
    },
  };

  useEffect(() => {
    // console.log('AAHJ', props);
    if (user) {
      if (props.type == 'conta') {
        setNavContent(content.conta);
      } else if (user.type_id == 1) {
        setNavContent(content.configuracoes);
      } else if (user.principal == 1) {
        setNavContent(content.empresa);
      } else {
        switch (props.type) {
          case 'dashboards':
            // setNavContent(content.dashboards);
            break;

          default:
            break;
        }
      }
    }
  }, [user]);

  const activeClass = menu => {
    if (menu === active) {
      return 'link-button active';
    }
    return 'link-button';
  };

  return (
    <section id="dashboard-nav">
      {navContent && navContent.title && (
        <>
          <span className="title">{navContent.title}</span>

          <span className="navigation">
            {navContent.links.map((data, index) => (
              <button key={index} className={activeClass(data.class)}>
                <Link to={data.link}>
                  <FontAwesomeIcon icon={data.icon} />
                  {data.text}
                </Link>
              </button>
            ))}
          </span>
        </>
      )}
    </section>
  );
};

export default Nav;
