import React from "react";

import "./styles.css";

const Title = (props) => {
  return (
    <span className={"title-element " + props.color}>{props.content}</span>
  );
};

export default Title;
