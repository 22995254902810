import React, { useState, useEffect, useRef } from 'react';

import Yup from '~/config/yup';

import { Form } from '@unform/web';
import InputText from '../../form/InputText';
import { useParams, useHistory } from 'react-router-dom';
import './styles.css';

import { newPassword, checkToken, updatePassword } from '~/models/users';
import { signInRequest } from '~/store/modules/auth/actions';
import { updateFirst } from '~/store/modules/user/actions';
import { useDispatch } from 'react-redux';
// import history from '~/services/history';
const RedefinirSenha = ({ first }) => {
  const [password, setPassword] = useState('');

  const [text, setText] = useState({});
  const [repeatPassword, setRepeatPassword] = useState('');

  const [invalidPassword, setInvalidPassword] = useState(first);
  const [invalidRepeatPassword, setInvalidRepeatPassword] = useState(false);

  const params = useParams();
  const validation = Yup.object().shape({
    password: Yup.string()
      .required()
      .min(8)
      .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/),
  });

  useEffect(() => {
    if (!first) {
      setText({
        title: 'Redefinir sua senha',
        password: 'Digite sua nova senha',
        repeatPassword: 'Digite sua nova senha mais uma vez',
      });

      checkToken(params.token);
    } else {
      setText({
        title: 'Primeiro Acesso',
        password: 'Crie sua senha',
        repeatPassword: 'Digite sua nova senha mais uma vez',
        auxiliar: (
          <p>
            Este é seu primeiro acesso. Para continuar, escolha uma nova senha.
            {/* <br />
            senha deverá ser diferente da atual, ter no mínimo 8 caracteres, uma
            letra maiúscula, uma minúscula, <br />
            um número e um caractere especial. Ex: Inpacto@20 */}
          </p>
        ),
      });
    }
  }, []);

  const formRef = useRef(null);

  const dispatch = useDispatch();

  let history = useHistory();
  const handleSubmit = async data => {
    const keep = true;
    // console.log('handleSubmit', data, invalidRepeatPassword, invalidPassword);
    if (!invalidPassword && !invalidRepeatPassword) {
      if (first) {
        // console.log('before dispatch');
        const response = await updatePassword(data.repeatPassword);

        // console.log('before response', response);
        dispatch(updateFirst());

        // console.log('after dispatch', history);

        history.push({
          pathname: '/dashboard',
          state: { email: data.email },
        });
      } else {
        const response = await newPassword(params.token, data.repeatPassword);
        if (keep) {
          // console.log(
          //   'response.email, password, kee',
          //   response.email,
          //   password,
          //   keep
          // );

          dispatch(signInRequest(response.email, password, keep));
        }
      }
    }
  };

  const validate = async (value, field) => {
    switch (field) {
      case 'password':
        const validPass = await validation.isValid({ password: value });

        setPassword(value);
        setInvalidPassword(!validPass);
        break;
      case 'repeatPassword':
        const validRepeat = password === value;

        setRepeatPassword(value);
        setInvalidRepeatPassword(!validRepeat);
        break;
      default:
        break;
    }
  };

  return (
    <section id="redefinir-senha">
      <div className="page-wrapper center">
        <Form ref={formRef} onSubmit={handleSubmit}>
          <h1>{text.title}</h1>
          {text.auxiliar && (
            <p className="auxiliar">
              {text.auxiliar}
              <br />
            </p>
          )}
          <div className="row">
            <InputText
              label={text.password}
              mandatory={true}
              name="repeatPassword"
              type="password"
              value={password}
              invalid={invalidPassword}
              wrapperClasslist="is-half"
              errorMessage2="Mínimo de 8 caracteres, uma letra maiúscula, uma letra minúscula, um número e um caractere especial. <span>Ex. Inpacto@20</span>"
              handleChange={e => validate(e, 'password')}
            />
            <InputText
              name="repeatPassword"
              label={text.repeatPassword}
              mandatory={true}
              type="password"
              value={repeatPassword}
              invalid={invalidRepeatPassword}
              wrapperClasslist="is-half"
              errorMessage2="Senha diferente da informada."
              handleChange={e => validate(e, 'repeatPassword')}
            />
          </div>
          <p>
            <input type="checkbox" value="0" name="checkbox-lembrar" id="checkbox-lembrar" />
            <label htmlFor="checkbox-lembrar">Mantenha-me conectado</label>
          </p>
          <button type="submit" className="button btn-primary">
            Enviar
          </button>
        </Form>
      </div>
    </section>
  );
};

export default RedefinirSenha;
