import React from "react";

import "./styles.css";

const Label = (props) => {
  let warning = "";
  if (props.mandatory) {
    warning = <span className="orange-text">*</span>;
  }
  if (props.text) {
    if (props.labelhtml === true) {
      return (
        <label
          className="input-label"
          dangerouslySetInnerHTML={{
            __html: props.text,
          }}
        ></label>
      );
    } else {
      return (
        <label className="input-label">
          {props.text} {warning}
        </label>
      );
    }
  }
  return "";
};

export default Label;
