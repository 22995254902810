import React from "react";

import { Header, Rodape, RecuperarSenha } from "../../components/sections";

import "../../style.css";

const RecuperarSenhaPage = () => {
  document.title = "InPACTO | Recuperar Senha";

  return (
    <div id="login-page-wrapper">
      <Header empty={true} />
      <RecuperarSenha />
      <Rodape />
    </div>
  );
};

export default RecuperarSenhaPage;
